import { Component, EventEmitter, Output, ViewChild, NgZone, Renderer2, ElementRef } from '@angular/core';
import { ModalDirective } from 'angular-bootstrap-md';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ConfigService } from './config.service';
import { CookieService } from 'ngx-cookie-service';
import { User } from './user.interface';
import { TranslateService } from '@ngx-translate/core';
import { Currency } from './currency.interface';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { InteractionService } from './interaction.service';
import { Round } from './round.interface';
import { ShieldHelper } from './Helpers/ShieldHelper';
import { NgxSpinnerService } from 'ngx-spinner';
import { Withdraw } from './withdraw.interface';
import { DocumentResponseDto } from './document.interface';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef } from '@angular/core';
import { MatCheckbox, MatSidenavContainer } from '@angular/material';
import { Slides } from './slide.interface';
import { DatePipe } from '@angular/common';
import * as store from 'store';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ToastService } from './toast.service';
import { filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ModalsService } from '../app/services/modals/modals.service'
import { EUserActionsModalType } from './Interfaces/Enumerators/EUserActionsModalType';
import { AuthService } from './services/auth/auth.service';
const MINUTES_UNITL_AUTO_LOGOUT = 43200
const CHECK_INTERVALL = 1000 // in ms
const STORE_KEY = 'lastAction';

export interface DateTicketStruct {
  id: number;
  datetime: string;
  uuid8: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  @ViewChild('sidemenu', { static: true }) sideMenu: MatSidenavContainer;

  @ViewChild('mattrigger', { static: false }) mattrigger: any;
  @ViewChild('mattrigger2', { static: false }) mattrigger2: any;

  @ViewChild('confirmCheckbox', { static: true }) confirmCheckbox: MatCheckbox;

  @ViewChild('sportMenu') sportMenu: any;
  @ViewChild('frameTransactions') frameTransactions: any;
  @ViewChild('frameChangePassword') frameChangePassword: any;
  @ViewChild('frameCheckout') frameCheckout: any;
  @ViewChild('videosTutorialModal') videosTutorialModal: any;
  @ViewChild('frameSiteIntroduction') frameSiteIntroduction: any;
  @ViewChild('frameHowToRegister') frameHowToRegister: any;
  @ViewChild('frameHowToDeposit') frameHowToDeposit: any;
  @ViewChild('frameHowToBet') frameHowToBet: any;
  @ViewChild('frameSendDocs') frameSendDocs: any;

  @ViewChild('frameChooseTicket') modalChooseTicket: ModalDirective;


  username: string;
  hasRest: boolean;
  docList: DocumentResponseDto[];
  withdrawList: Withdraw[];

  introductionVideoUrl: SafeResourceUrl;
  registerVideoUrl: SafeResourceUrl;
  depositVideoUrl: SafeResourceUrl;
  betVideoUrl: SafeResourceUrl;
  sendDocsVideoUrl: SafeResourceUrl;
  checkoutVideoUrl: SafeResourceUrl;
  changePasswordVideoUrl: SafeResourceUrl;
  transactionVideoUrl: SafeResourceUrl;
  jiuJitsuMatch: number;
  isResumeTicket: boolean = false;
  currencies: string = '';
  activeCurrency: string = 'BRL';
  currencyList = [];
  currencyList2 = [];
  hubId: number;
  myUrl: string = location.protocol + '//' + location.host + "/home";
  public pegaModal() {
    return ModalDirective;

  }

  leftSlideList: Slides[] = [];
  slidesPathPrefix = "http://poolbet365.com/assets/banners/";
  //slidesPathPrefix = "http://localhost/assets/banners/"; //local test
  roundValue: number;
  round = {} as Round;
  title = 'mdb-angular-free';
  userAdmin: any;
  isMenuOpen: Boolean = false;
  public activeLang;
  activeLangCountry = '';

  public cookie: string;
  public betIdPrint: number;

  Currency: Currency = <Currency>{};
  loginData: any = {};
  public nextUrl: string = '';
  mobileQuery: MediaQueryList;
  roundName: string;
  singleMatch: number;
  radarSport: number;
  processingClick: boolean = true;
  isDropdownOpen = false;
  isLoading: boolean = false;
  status: string = "";
  eachPrize: number = 0;
  actualUrl: string = "";
  isHomePage: boolean = false;
  dateTicketsList: DateTicketStruct[] = [];
  mainLogo: string = 'logo-principal.png';
  menuCasino: true;
  menuLiveCasino: true;
  menuCrashGames: true;
  backgroundColor: string = '#1f1f1fcb';
  mainLogoWidth: string = "200px";
  isLogged: boolean = false;
  blockedCountry: boolean = false;

  private _mobileQueryListener: () => void;

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  @Output()
  sendData: EventEmitter<any>;

  // shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));

  constructor(
    private interactionservice: InteractionService,
    private configService: ConfigService,
    private cookieService: CookieService,
    private translate: TranslateService,
    public router: Router,
    private spinnerService: NgxSpinnerService,
    private _formBuilder: FormBuilder,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private datePipe: DatePipe,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2, private el: ElementRef,
    private toastService: ToastService,
    private http: HttpClient,
    private modalsService: ModalsService,
    private titleService: Title,
    private authService: AuthService
  ) {
    this.interactionservice.skinConfig.subscribe(data => {
      this.setMenuFromDomain(data.club);
      this.mainLogo = data.mainLogo;
      this.mainLogoWidth = data.mainLogoWidth;
      this.backgroundColor = data.background;
      this.titleService.setTitle(data.pageTitle);
    })
    this.sendData = new EventEmitter<any>();
    this.hubId = this.configService.creatorId;
    this.mobileQuery = media.matchMedia('(max-width: 989px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.check();
    this.initListener();
    this.initInterval();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (event.url == '/' || event.url == '' || event.url.includes('/home')) {
        this.isHomePage = true
      } else {
        this.isHomePage = false
      }
      this.actualUrl = event.url;
    });

    this.authService.blockedCountry.subscribe((blocked: boolean) => {
      this.blockedCountry = blocked;
    });
  }

  getOwnLeftSlides(masterParentId: number, languageId: number, slideType: string) {
    this.configService.getOwnSlides(this.hubId, languageId, slideType)
      .subscribe(data => {
        this.leftSlideList = data;
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant('pg-banners.operation-fail'), this.translate.instant('pg-banners.load-fail-info'), true);
      });
  }

  ngOnInit() {
    this.interactionservice.userAdmin.subscribe(userAdmin => {
      this.userAdmin = userAdmin;
    })

    this.interactionservice.loginData.subscribe(loginData => {
      this.loginData = loginData;
    })

    this.interactionservice.isLogged.subscribe(logged => {
      this.isLogged = logged;
    })


    this.http.get('https://www.cloudflare.com/cdn-cgi/trace', { responseType: 'text' })
      .subscribe(resp => {
        let response = resp.trim().split('\n');
        let ip = response[2].split("=")[1];

        localStorage.setItem('currentUserIp', ip);

        this.setTimeZonedifference(ip);
      }, error => {
        console.log(error);
      })

    let curUserId = Number(localStorage.getItem('currentUser'));
    if (curUserId <= 0) {
      curUserId = 1;
    }
    if (localStorage.getItem('language') == null) {
      this.activeLang = 'pt';
      this.activeLangCountry = "pt"
      this.changeLanguage(this.activeLang, this.activeLangCountry);
    } else {
      this.activeLang = localStorage.getItem('language');
      this.activeLangCountry = localStorage.getItem('languageCountry');
      this.changeLanguage(this.activeLang, this.activeLangCountry);
    }
    this.getOwnLeftSlides(curUserId, this.configService.getLanguageId(this.activeLang), "left");
    this.hasRest = false;
    this.docList = [];
    this.withdrawList = [];
    // this.spinner();
    //this.MoveTime();
    this.interactionservice.homeVisibleRound
      .subscribe(data => {
        if (data != null) {
          this.round = data;
        }
      });

    // menu border bottom
    // if (window.location.pathname == '/home') {
    //   document.getElementById('headerHome').classList.add('button-header');
    // } else if (window.location.pathname == '/result') {
    //   document.getElementById('headerResult').classList.add('button-header');
    // } else if (window.location.pathname == '/ticket') {
    //   document.getElementById('headerTicket').classList.add('button-header');
    // } else if (window.location.pathname == '/rule') {
    //   document.getElementById('headerRule').classList.add('button-header');
    // } else if (window.location.pathname == '/rule') {
    //   document.getElementById('headerContact').classList.add('button-header');
    // } else if (window.location.pathname == '/contact') {
    //   document.getElementById('headerContact').classList.add('button-header');
    // }

    // menu border bottom
    if (window.location.pathname == '/home') {
      document.getElementById('headerHome')?.classList.add('button-header');
    } else if (window.location.pathname == '/result') {
      document.getElementById('headerResult')?.classList.add('button-header');
    } else if (window.location.pathname == '/ticket') {
      document.getElementById('headerTicket')?.classList.add('button-header');
    } else if (window.location.pathname == '/contact') {
      document.getElementById('headerContact')?.classList.add('button-header');
    }


    // AUTO LOGIN
    if (localStorage.getItem('currentUser') != null) {
      const userId = Number(localStorage.getItem('currentUser'))
      if (this.userAdmin == null) {
        this.configService.getUser(userId)
          .subscribe(data => {
            this.interactionservice.setUserAdmin(data)
            this.interactionservice.setLoginData({
              message: '',
              token: '',
              user: data
            });
            this.cookieService.set('user', JSON.stringify(data));
            localStorage.setItem('currentUser', data.id.toString());
            this.interactionservice.setIsLogged(true);
          })
      }
    }
    // END AUTO LOGIN

    this.configService.setGlobalCurrency();

    this.interactionservice.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });

    // automatic update credit when menu player is opened
    setInterval(() => {
      if (typeof (this.mattrigger) != 'undefined' && this.mattrigger.menuOpen) {
        this.CredidLoad();
      }
      else if (typeof (this.mattrigger2) != 'undefined' && this.mattrigger2.menuOpen) {
        this.CredidLoad();
      }
    }, 10000);


    this.route.queryParamMap.subscribe(params => {
      let code = params.get('code');
      if (code != null) {
        this.interactionservice.setRefCode(code ?? '');
        this.modalsService.openUserActionsModal(EUserActionsModalType.Register)
      }
    });

    let currentCountry = localStorage.getItem('currentUserCountry');
    if (currentCountry != null) {
      switch (currentCountry) {
        case 'Brazil':
          this.activeCurrency = 'BRL';
          break;

        case 'United States of America':
          this.activeCurrency = 'USD';
          break;

        case 'Italy':
          this.activeCurrency = 'EUR';
          break;

        case 'United Kingdom':
          this.activeCurrency = 'GBP';
          break;

        case 'Romania':
          this.activeCurrency = 'RON';
          break;

        case 'Turkey':
          this.activeCurrency = 'TRY';
          break;

        case 'Morocco':
          this.activeCurrency = 'MAD';
          break;
      }
    }
  }

  openLoginModal(): void {
    this.modalsService.openUserActionsModal(EUserActionsModalType.Login)
  }

  openRegisterModal(): void {
    this.modalsService.openUserActionsModal(EUserActionsModalType.Register);
  }

  openChangePasswordModal(): void {
    this.modalsService.openUserActionsModal(EUserActionsModalType.ChangePassword);
  }

  setMenuFromDomain(clubId: number) {
    this.configService.setMenuFromDomain(clubId)
      .subscribe(data => {
        this.menuCasino = data.casino;
        this.menuLiveCasino = data.liveCasino;
        this.menuCrashGames = data.crashGames;
        this.hubId = data.hubID;
      }, error => {
        console.log(error);
      });
  }

  toggleMenu() {
    const userId: number = parseInt(localStorage.getItem("currentUser"));
    this.configService.getUser(userId)
      .subscribe((data) => {
        this.userAdmin.credit = data.credit;
      })
    this.isMenuOpen = !this.isMenuOpen;
    const body = this.el.nativeElement.ownerDocument.body;
    if (this.isMenuOpen) {
      this.renderer.addClass(body, 'menu-open');
    } else {
      this.renderer.removeClass(body, 'menu-open');
    }
  }

  adjustLetter(element: string) {
    let letter = element.toLocaleLowerCase();
    let firstLetter = letter[0].toLocaleUpperCase();
    letter = letter.slice(1)
    letter = firstLetter + letter;
    return letter;
  }

  GetCurrencyUser(Id: number) {
    this.configService.GetCurrencyUser(Id)
      .subscribe(data => {
        this.Currency.currency = data.currencyId;
        this.Currency.country = data.name;
        this.Currency.currencySymbol = data.currencySymbol;
        this.interactionservice.setGlobalCountry(this.Currency);
      }, error => {


      });
  }

  isActiveRoute(routerLink: string): boolean {
    return this.router.isActive(routerLink, true);
  }



  CredidLoad() {
    // reloading user's info
    this.configService.getUser(this.userAdmin.id)
      .subscribe((data: User) => {
        if (this.userAdmin) {
          this.userAdmin.country = data.country;
          this.userAdmin.credit = data.credit;
          this.userAdmin.Jurisdiction = data.jurisdiction;
          if (data.country == null) {
            localStorage.setItem('currentUserCountry', 'Brazil');
          } else localStorage.setItem('currentUserCountry', data.currencyCode);
        }

        // this.checkAPIVisitorCredit();
      }, error => {
        // this.checkAPIVisitorCredit();
      });
  }

  // Check API visitors credit
  // checkAPIVisitorCredit() {
  //   this.setTimeOut();
  //   this.configService.getVisitorCredit(4001, this.userAdmin.id, this.userAdmin.credit)
  //     .subscribe(visitor => {
  //       if (visitor != null) {
  //         this.userAdmin.credit = +visitor.Credit;
  //         this.configService.updateUser(this.userAdmin.id, this.userAdmin)
  //           .subscribe(data => {
  //             //...
  //           }, error => {
  //             //....
  //           })
  //       }
  //     }, err => {
  //       //console.log(err);
  //     });
  // }

  // Update API visitors credit
  // updateAPIVisitorCredit(credit: number) {
  //   this.configService.getVisitorCredit(4003, this.userAdmin.id, credit)
  //     .subscribe(visitor => {
  //       //this.userAdmin.credit = visitor;
  //     }, err => {
  //       console.log(err);
  //     });
  // }

  // setTimeOut() {
  //   // timeout logout
  //   //let timeToLogoff = this.userAdmin.jurisdictionId == 120 ? 10 : 240;
  //   let timeToLogoff = 2880;
  //   if (this.cookieService.get('user') != null && this.cookieService.get('user') != '') {
  //     this.bnIdle.startWatching(timeToLogoff).subscribe((res) => {
  //       if (res) {
  //         this.logout();
  //       }
  //     });
  //   }
  //   ;
  // }

  logout() {
    this.cookieService.deleteAll();
    this.interactionservice.setIsLogged(false);
    this.isLogged = false;
    this.userAdmin = null;

    this.interactionservice.setUserAdmin(null);
    this.interactionservice.setLoginData(null);
    this.interactionservice.setIsLogged(false);

    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentUserToken');
    this.router.navigate(['/home']);
  }

  // --- LANGUAGE COMPONENTS --//
  public changeLanguage(lang, country) {
    this.activeLang = lang;
    this.activeLangCountry = country;
    this.translate.use(lang);
    // for print window.open
    localStorage.setItem('language', lang);
    localStorage.setItem('languageCountry', country);
    this.configService.raiseSelLanEvent(lang);

    let curUserId = Number(localStorage.getItem('currentUser'));
    if (curUserId <= 0) {
      curUserId = 1;
    }
    this.getOwnLeftSlides(curUserId, this.configService.getLanguageId(lang), "left");
    this.clearVideosUrl();
    // this.setVideoTutorialsUrl(lang);
    if (this.router.url.includes('bet-list')) {
      this.reloadBetList();
    }
  }

  // --- LANGUAGE COMPONENTS --//

  reloadBetList() {
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(currentRoute);
    })
  }

  // setVideoTutorialsUrl(language: string) {
  //   setTimeout(() => {
  //     switch (language) {
  //       case 'pt':
  //         this.introductionVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/UacYRdYhGzI');
  //         this.registerVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/RfEBnWE9f1o');
  //         this.depositVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/k2kyNuuWrLQ');
  //         this.betVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/M9VQTMAVEu8');
  //         this.sendDocsVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/cJhr_scpw78');
  //         this.checkoutVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/iVAftwMFh4Q');
  //         this.changePasswordVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/bFHzFcX9KHQ');
  //         this.transactionVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/ge1d-ros0jw');
  //         break;
  //       case 'en':
  //         this.introductionVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/qqoUt7ADvBQ');
  //         this.registerVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/aTVdY8Eb028');
  //         this.depositVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/nTnEn99-9k4');
  //         this.betVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/P8h--vbOPvM');
  //         this.sendDocsVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/V_ZmSo-Zu-4');
  //         this.checkoutVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/yT8CiseQ5Zw');
  //         this.changePasswordVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/VJrYw_cNizQ');
  //         this.transactionVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/KUjlK9BUv0E');
  //         break;
  //       case 'it':
  //         this.introductionVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/z1Nld2ateMA');
  //         this.registerVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/kdEgcllJvBY');
  //         this.depositVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/ZXE93_upoYA');
  //         this.betVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/tNQepK1QogQ');
  //         this.sendDocsVideoUrl = '';
  //         this.checkoutVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/X8Dtg78bzsM');
  //         this.changePasswordVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/UsspYcH_nnw');
  //         this.transactionVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/CNWGKS4ts6M');
  //         break;
  //       case 'es':
  //         this.introductionVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/_cuCKZdHeNw');
  //         this.registerVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/XNnL9d_FS9o');
  //         this.depositVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/dtC2MjzeFqs');
  //         this.betVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/KxKnwwTvqTk');
  //         this.sendDocsVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/Rp48Pct4bjI');
  //         this.checkoutVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/avVxObFdL1Y');
  //         this.changePasswordVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/L7MiVMvgH_s');
  //         this.transactionVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/ujqdsR4pzlk');
  //         break;
  //     }
  //     this.isLoading = false;
  //   }, 2000);
  // }

  clearVideosUrl() {
    this.introductionVideoUrl = '';
    this.registerVideoUrl = '';
    this.depositVideoUrl = '';
    this.betVideoUrl = '';
    this.sendDocsVideoUrl = '';
    this.checkoutVideoUrl = '';
    this.changePasswordVideoUrl = '';
    this.transactionVideoUrl = '';
    this.isLoading = true;
  }

  // --- MSG COMMON MODAL ---/


  listClick(event) {
    if (document.getElementsByClassName('button-header').length > 0) {
      document.getElementsByClassName('button-header')[0].classList.remove('button-header');
    }
    document.getElementById(event.currentTarget.id).classList.add('button-header');

  }

  // closeRoundMenu() {
  //   this.showCloseRoundBtn = false;
  //   this.roundMenu.close();
  // }


  ticketListMobileOpen() {
    document.getElementById("dropdown-content").classList.toggle("show");
  }

  cancelbgyellow() {
    let contbgyellow = document.querySelectorAll('table .bg-yellow').length;

    while (contbgyellow != 0) {
      if (document.querySelectorAll('.bg-yellow').length != 0) {
        document.querySelector('table .bg-yellow').classList.remove('bg-yellow');
        document.getElementById('cupomhtml').innerHTML = ' <div id=\'txtcupomhtml\'>' + this.translate.instant('pg-home.bet-selected') + '</div> ';
      }
      contbgyellow = contbgyellow - 1;
    }
  }


  setTimeZonedifference(ip: any) {
    this.configService.getTimeZone(ip)
      .subscribe(resp => {
        let hours = parseInt(resp.utc_offset.substring(1, 3), 10);
        let minutes = parseInt(resp.utc_offset.substring(3, 5), 10);

        minutes = hours * 60 + minutes;

        if (resp.utc_offset[0] === '-')
          minutes = minutes * -1;

        minutes = minutes + 180;

        localStorage.setItem('minute', `${minutes}`);
      });
  }

  spinner(): void {
    this.spinnerService.show();
    setTimeout(() => {

      this.spinnerService.hide();
    }, 5000);

  }

  getFormula1Shield(driverName: string) {
    return ShieldHelper.getFormula1Shield(driverName);
  }

  getSelectedShield(name: string, betsport: number) {
    return ShieldHelper.getSelectedShield(name, betsport);
  }

  checkCouponQuantity(ticketCode) {
    this.configService.getBetByUuid(ticketCode)
      .subscribe(bets => {
        if (bets.length > 1) {
          this.dateTicketsList = [];
          bets.forEach(element => {

            const dateTicket: DateTicketStruct = {
              id: element.id,
              datetime: this.datePipe.transform(element.betDate, 'dd/MM/y HH:mm'),
              uuid8: element.uuid8
            };

            this.dateTicketsList.push(dateTicket);
          });
          this.modalChooseTicket.show();
        }
        else {
          this.detailCoupon(ticketCode);
        }
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-tickets.ticket-not-found"), this.translate.instant("comp-search-ticket.fail-info1") + ticketCode + this.translate.instant("comp-search-ticket.fail-info2"), true);
      });
  }

  detailCoupon(ticketCode) {
    this.modalsService.openDetailTickeModal(0, ticketCode);
  }

  detailCouponwithId(betId) {
    this.modalsService.openDetailTickeModal(betId, "");
  }

  //auto log out
  get lastAction() {
    return parseInt(store.get(STORE_KEY));
  }

  set lastAction(value) {
    store.set(STORE_KEY, value);
  }

  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
    });
  }

  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, CHECK_INTERVALL);
    })
  }
  reset() {
    this.lastAction = Date.now();
  }
  check() {
    const now = Date.now();
    const timeleft = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    this.ngZone.run(() => {

      if (isTimeout && this.isLogged) {
        this.logout();
        this.router.navigate(['/home']);
      }
    });
  }

  setCurrency(countryName: string, currencyId: string) {
    localStorage.setItem('currentUserCountry', `${countryName}`);
    this.configService.setGlobalCurrency();
    this.activeCurrency = currencyId;
    this.interactionservice.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });
  }

  getSportbookUrl() {
    if (this.userAdmin) {
      let sportBookWindow = window.open();
      sportBookWindow.document.write(`
      <html>
        <head>
        <style>

        body {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .loader{
          font-size: 48px;
          color: #000;
          display: inline-block;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: 400;
          position: relative;
        }
        .loader:after{
          content: '';
          height: 4px;
          width:0%;
          display: block;
          background: #00ca44;
          animation: 5s lineGrow linear infinite;
        }

        @keyframes lineGrow {to{width: 100%;}}
        </style>
        </head>
        <body> <span class="loader">loading</span> </body>
      </html>
      `);
      this.configService.getSportbookUrl(this.userAdmin.id)
        .subscribe((data: any) => {
          sportBookWindow.location = data.url;
        });
    } else { return this.toastService.showNotification({ message: this.translate.instant("pg-home.must-be-logged"), type: 'danger' }); };
  }

  getSportbookUrlMobile() {
    if (this.userAdmin) {
      this.configService.getSportbookUrl(this.userAdmin.id)
        .subscribe((data: any) => {
          window.location = data.url;
        });
    } else { return this.toastService.showNotification({ message: this.translate.instant("pg-home.must-be-logged"), type: 'danger' }); };
  }

}
