import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  blockedCountry = new BehaviorSubject<boolean>(false);

  constructor() { }

  setBlockedCountry(blocked: boolean) {
    this.blockedCountry.next(blocked);
  }

}
