import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BetService } from '../../../services/bet-service/bet.service'
import { DetailCoupon } from 'src/app/Interfaces/DetailCoupon/DetailCoupon.interface';
import { ShieldHelper } from 'src/app/Helpers/ShieldHelper';
import { DatePipe } from '@angular/common';
import { ConfigService } from 'src/app/config.service';
import { TableChoicesMadeService } from 'src/app/services/table-choices-made/table-choices-made.service';
import { ModalsService } from '../../../services/modals/modals.service';

@Component({
  selector: 'app-detail-coupon',
  templateUrl: './detail-coupon.component.html',
  styleUrls: ['./detail-coupon.component.scss']
})
export class DetailCouponComponent implements OnInit {
  @Input() id: number;
  @Input() uuid: string;

  detail: DetailCoupon = {
    bet: null,
    winner: false,
    roundResult: "",
    roundName: '',
    roundType: 0,
    roundSportId: 0,
    roundPosted: false,
    events: null,
    matches: null
  };

  details: DetailCoupon[] = [];

  multipleBets: boolean = true;

  isVisible: boolean = false;
  tableOfChoices: string = '';
  canceled: number = 0;
  numberEventsCanceled: number = 0;

  constructor(private translate: TranslateService, private betService: BetService, public datePipe: DatePipe,
    public configService: ConfigService, private modalsService: ModalsService) {
    this.modalsService.registerDetailsTicketModal(this);
  }

  ngOnInit(): void {
    // this.open(0, '616de6f4');
  }

  open(id: number, uuid: string | null) {
    this.betService.getBetDetail(id, uuid == undefined ? "" : uuid)
      .subscribe(response => {
        debugger;
        this.details = response;
        if (response.length > 1) {
          this.multipleBets = true;
          this.isVisible = true;
        }else{
          this.detail = response[0];
          this.renderTableOfChoices();
          this.multipleBets = false;
        }
      }, error => {

      });
  }

  closeModal() {
    this.isVisible = false;
  }

  selectCoupon(detail: DetailCoupon){
    this.detail = detail;
    this.renderTableOfChoices();
    this.multipleBets = false;
  }

  checkWinner(winner: boolean) {
    return winner ? "Bet won" : "Lost Bet";
  }

  getBasicShield() {
    return ShieldHelper.getChampionshipLogos(this.detail.roundName);
  }

  getSingleMatchShield(number) {
    return ShieldHelper.getSingleMatchShield(this.detail.roundName, number);
  }

  renderTableOfChoices() {
    switch (this.detail.roundType) {
      case 0:
        this.tableOfChoices = this.generateTableForDynamicStyle();
        break;
      case 1:
        this.tableOfChoices = this.generateTableForChampionship();
        break;
      case 2:
        this.tableOfChoices = this.generateTableForRank();
        break;
    }

    this.isVisible = true;
  }

  generateTableForDynamicStyle() {
    let tableOfChoices: string = '';
    let betChoices = this.detail.bet.results.split('|');

    if (this.detail.roundPosted) {
      let results = this.detail.roundResult.split('|');

      betChoices.forEach((choice, i) => {
        switch (this.detail.events[i].type) {
          case 0:
            if (choice.toLowerCase().includes(results[i].toLowerCase())) {
              tableOfChoices += TableChoicesMadeService.GenarateConfirmTable3Choices(this.translate.instant(this.detail.events[i].name), choice.toLowerCase());
            } else {
              tableOfChoices += TableChoicesMadeService.GenarateNoHitTable3Choices(this.translate.instant(this.detail.events[i].name), choice.toLowerCase());
            }
            break;

          case 1:
            if (choice.toLowerCase().includes(results[i].toLowerCase())) {
              tableOfChoices += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant(this.detail.events[i].name), choice.toLowerCase(), this.translate.instant("1"), this.translate.instant("2"));
            } else {
              tableOfChoices += TableChoicesMadeService.GenerateNoHitTable2Choices(this.translate.instant(this.detail.events[i].name), choice.toLowerCase(), this.translate.instant("1"), this.translate.instant("2"));
            }
            break;

          case 2:
            if (choice.toLowerCase().includes(results[i].toLowerCase())) {
              tableOfChoices += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant(this.detail.events[i].name), choice.toLowerCase(),
                this.detail.events[i].odd, this.translate.instant("Over"), this.translate.instant("Under"));
            } else {
              tableOfChoices += TableChoicesMadeService.GenarateNoHitTable2ChoicesOverUnder(this.translate.instant(this.detail.events[i].name), choice.toLowerCase(),
                this.detail.events[i].odd, this.translate.instant("Over"), this.translate.instant("Under"));
            }
            break;

          case 3:
            if (choice.toLowerCase().includes(results[i].toLowerCase())) {
              tableOfChoices += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant(this.detail.events[i].name), choice.toLowerCase(), this.translate.instant("Yes"), this.translate.instant("No"));
            } else {
              tableOfChoices += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant(this.detail.events[i].name), choice.toLowerCase(), this.translate.instant("Yes"), this.translate.instant("No"));
            }
            break;

          case 4:
            if (choice.toLowerCase().includes(results[i].toLowerCase())) {
              tableOfChoices += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant(this.detail.events[i].name), choice.toLowerCase(), this.translate.instant("Even"), this.translate.instant("Odd"));
            } else {
              tableOfChoices += TableChoicesMadeService.GenerateNoHitTable2Choices(this.translate.instant(this.detail.events[i].name), choice.toLowerCase(), this.translate.instant("Even"), this.translate.instant("Odd"));
            }
            break;

          case 5:
            if (choice.toLowerCase().includes(results[i].toLowerCase())) {
              tableOfChoices += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant(this.detail.events[i].name), choice.toLowerCase(), this.translate.instant("Home"), this.translate.instant("Away"));
            } else {
              tableOfChoices += TableChoicesMadeService.GenerateNoHitTable2Choices(this.translate.instant(this.detail.events[i].name), choice.toLowerCase(), this.translate.instant("Home"), this.translate.instant("Away"));
            }
            break;

          case 6:
            if (choice.toLowerCase().includes(results[i].toLowerCase())) {
              tableOfChoices += TableChoicesMadeService.GenarateConfirmTable3ChoicesDynamic(this.translate.instant(this.detail.events[i].name), choice.toLowerCase(), this.translate.instant("Home"),
                this.translate.instant("Draw"), this.translate.instant("Away"));
            } else {
              tableOfChoices += TableChoicesMadeService.GenarateNoHitTable3ChoicesDynamic(this.translate.instant(this.detail.events[i].name), choice.toLowerCase(), this.translate.instant("Home"),
                this.translate.instant("Draw"), this.translate.instant("Away"));
            }
            break
        }
      });
    } else {
      betChoices.forEach((choice, i) => {
        switch (this.detail.events[i].type) {
          case 0:
            tableOfChoices += TableChoicesMadeService.GenarateConfirmTable3Choices(this.translate.instant(this.detail.events[i].name), choice.toLowerCase());
            break;

          case 1:
            tableOfChoices += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant(this.detail.events[i].name), choice.toLowerCase(), this.translate.instant("1"), this.translate.instant("2"));
            break;

          case 2:
            tableOfChoices += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant(this.detail.events[i].name), choice.toLowerCase(),
              this.detail.events[i].odd, this.translate.instant("Over"), this.translate.instant("Under"));
            break;

          case 3:
            tableOfChoices += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant(this.detail.events[i].name), choice.toLowerCase(), this.translate.instant("Yes"), this.translate.instant("No"));
            break;

          case 4:
            tableOfChoices += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant(this.detail.events[i].name), choice.toLowerCase(), this.translate.instant("Even"), this.translate.instant("Odd"));
            break;

          case 5:
            tableOfChoices += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant(this.detail.events[i].name), choice.toLowerCase(), this.translate.instant("Home"), this.translate.instant("Away"));
            break;

          case 6:
            tableOfChoices += TableChoicesMadeService.GenarateConfirmTable3ChoicesDynamic(this.translate.instant(this.detail.events[i].name), choice.toLowerCase(), this.translate.instant("Home"),
              this.translate.instant("Draw"), this.translate.instant("Away"));
            break
        }
      });
    }
    return tableOfChoices;
  }

  generateTableForChampionship(): string {
    let tableOfChoices: string = '';
    let betChoices = this.detail.bet.results.split('|');
    if (this.detail.roundPosted) {
      let results = this.detail.roundResult.split('|');

      betChoices.forEach((choice, i) => {
        if (choice.toLowerCase().includes(results[i].toLowerCase())) {
          tableOfChoices += TableChoicesMadeService.GenarateConfirmTable3Choices(this.detail.matches[i].homeName + " X " + this.detail.matches[i].awayName, choice.toLowerCase());
        }
        if (!choice.toLowerCase().includes(results[i].toLowerCase())) {
          if (results[i][0] == "¢")
            this.numberEventsCanceled++

          tableOfChoices += TableChoicesMadeService.GenarateNoHitTable3Choices(this.detail.matches[i].homeName + " X " + this.detail.matches[i].awayName, choice.toLowerCase());
        }
      });
    } else {
      betChoices.forEach((choice, i) => {
        tableOfChoices += TableChoicesMadeService.GenarateConfirmTable3Choices(this.detail.matches[i].homeName + " X " + this.detail.matches[i].awayName, choice.toLowerCase());
      });
    }

    return tableOfChoices;
  }

  generateTableForRank(): string {
    let tableOfChoices: string = '';
    let betChoices = this.detail.bet.results.split('|');

    if (this.detail.roundPosted) {
      let results = this.detail.roundResult.split('|');
      betChoices.forEach((choice, i) => {
        if (choice.toLowerCase() == results[i].toLowerCase()) {
          tableOfChoices += TableChoicesMadeService.GenerateResultTableRankMatch(ShieldHelper.getSelectedShield(choice, this.detail.roundSportId), choice, `${(i + 1)}°`);
        }
        if (choice.toLowerCase() != results[i].toLowerCase()) {
          if (results[i][0] == "¢")
            this.numberEventsCanceled++
          tableOfChoices += TableChoicesMadeService.GenerateNoHitTableRankMatch(ShieldHelper.getSelectedShield(choice, this.detail.roundSportId), choice, `${(i + 1)}°`);
        }
      });
    } else {
      betChoices.forEach((choice, i) => {
        tableOfChoices += TableChoicesMadeService.GenerateResultTableRankMatch(ShieldHelper.getSelectedShield(choice, this.detail.roundSportId), choice, `${(i + 1)}°`);
      });
    }

    return tableOfChoices;
  }

  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section2').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>PoolBet365</title>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
          <style>
            body {
              font-family: Roboto, "Helvetica Neue", sans-serif;
              font-size: 13px;
            }

            #print-section2{
              margin: 0;
              width: 50%;
              max-height: 100vh;
            }

            .div-information-confirmation-ticket{
              padding: .15rem;
              display: flex;
              flex: row;
              flex-wrap: nowrap;
              width: 30%;
            }

            .bg-theme-grey{}

            .event-name-Confirmation{
              width: 50%;
              padding: 0.3rem;
              font-size: 13px;
            }

            .event-Choices-Confirmation{
              display: none;
            }

            .active-Confirmation-Choice{
              display: block !important;
              width: 14%;
              border-radius: 5px;
              color: #1b1b1b;
              margin: auto auto auto auto;
              font-weight: bold;
              text-align: center;
              padding: .25rem;
              font-size: 13px;
            }

            .bg-none{
              background-color: rgba(0, 0, 0, 0) !important;
            }

            .bg_green-text_white{
              background-color: rgba($color: #000, $alpha: 0) !important;
            }

            .style-flex-resume-cupom{
              display: flex !important;
              flex-direction: row !important;
              flex-wrap: nowrap !important;
              width: 80%;
              margin-left: 10%;
            }

            .modal-detail-legend{
              font-size: 15px;
              display: flex;
              justify-content: space-between;
              width: 25%;
              padding: .15rem;
            }

            .cancel-choice-event{
              width: 17% !important;
              margin: auto auto;
            }

            /*@page {
              size: auto;
              margin: 10;
            }
            @media print {
              html, body {
                width: 210mm;
                height: 297mm;
              }*/
          </style>
        </head>
        <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
}
