<main class="accordion main-box" id="accordionExample">
  <div class="d-flex text-white mobile-hide main-header" style="font-size: 16px; font-weight: 500;">
    <span class="d-inline-flex justify-content-center w-100 text-center header-date">{{ 'pg-post-result.date' |
      translate }} <br> {{ 'pg-manage-ticket.hour' | translate }}</span>
    <span class="d-inline-flex align-items-center justify-content-center header-pool">Pool</span>
    <div class="d-flex align-items-center header-prizes"
      style="width: 40%; justify-content: start; gap: 3%; padding-right: 2%;">
      <span class="d-inline-flex" style="width: 30%;">{{ "round-header.value" | translate}}</span>
      <span class="d-inline-flex" style="width: 50%;">{{ "round-header.prize" | translate}}</span>
      <span class="d-inline-flex" style="width: 40%;">{{ "round-header.jackpot" | translate}}</span>
    </div>
  </div>
  <section *ngFor="let round of rounds; let i = index; trackBy: trackByRounds" class="card"
    [ngClass]="showEvent(round)">

    <!-- Beggin mobile round header -->
    <header class="card-header d-flex flex-column desktop-hide" [id]="'heading' + round.uuid4" data-toggle="collapse"
      [attr.data-target]="'#collapse' + i" [attr.aria-controls]="'collapse' + i"
      (click)="selectRound(round.uuid4, null); scrollTo('heading' + round.uuid4)">
      <div class="d-flex w-100">
        <span
          style="background-color: #0F0D0D; color: white; font-size: 14px; display: inline-flex; text-align: center; width: 15% !important;">
          {{ round.startDateTimeFormatted }}
        </span>
        <span *ngIf="!round.singleMatchRound && !round.jiuJitsuRound"
          class="d-inline-flex text-align-center align-items-center w-100 pl-1"
          style="font-size: 15px; font-weight: 400;">
          <img width="25px" class="mr-1" src="{{round.homeShield}}"
            onerror="onerror=null; src='https://poolbet365.com/assets/shields/default-logo.png'">
          {{ round.name }}
        </span>
        <span *ngIf="round.singleMatchRound && round.jiuJitsuRound && round.betradarSportId != 21"
          class="d-inline-flex text-align-center align-items-center w-100 pl-1"
          style="font-size: 15px; font-weight: 400;">
          <img width="25px" class="mr-1" src="{{round.homeShield}}"
            onerror="onerror=null; src='https://poolbet365.com/assets/shields/default-logo.png'">
          {{ round.name }}
          <img src="{{round.awayShield}}" width="25px" class="ml-1"
            onerror="onerror=null;src='https://poolbet365.com/assets/shields/default-logo.png'" />
        </span>
        <span *ngIf="round.singleMatchRound && round.jiuJitsuRound  && round.betradarSportId == 21"
          class="d-inline-flex text-align-center align-items-center w-100 pl-1"
          style="font-size: 15px; font-weight: 400;">
          <img width="25px" class="mr-1" src="{{round.homeShield}}"
            onerror="onerror=null; src='https://poolbet365.com/assets/shields/default-logo.png'">
          {{ round.homeTeam }} ({{"Home" | translate}}) X {{round.awayTeam}} ({{"Away" | translate}})
          <img src="{{round.awayShield}}" width="25px" class="ml-1"
            onerror="onerror=null;src='https://poolbet365.com/assets/shields/default-logo.png'" />
        </span>
      </div>
      <div class="prizes d-flex w-100 justify-content-around">
        <div class="d-flex flex-column">
          <span>{{ "round-header.value" | translate}}</span>
          <span class="rounds-value">{{ AdjustCurrency(round.value) }}</span>
        </div>
        <div class="d-flex flex-column">
          <span>{{ "round-header.prize" | translate}}</span>
          <span class="rounds-value">{{ AdjustCurrency(round.prizes[1].gathered) }}</span>
        </div>
        <div class="d-flex flex-column">
          <span>{{ "round-header.jackpot" | translate}}</span>
          <span class="rounds-value">{{ AdjustCurrency(round.prizes[0].gathered) }}</span>
        </div>
      </div>
      <div class="d-flex flex-nowrap mt-1 pl-3 pb-1">
        <span class="bet-grid-footer mr-1 pl-2 pr-2">{{"Round" | translate}} {{ round.number }}</span>
        <span class="bet-grid-footer d-flex align-items-center mr-1 pl-2 pr-2">{{"events" | translate}}
          {{round.matches?.length || round.events?.length}}</span>
        <!-- <span class="bet-grid-footer mr-1 pl-2 pr-2">
          <i class='fas fa-ticket-alt mr-1' style='font-size: 12px;'></i> {{ round.totalBets | number:'1.0-0' }}
        </span> -->
        <span class="bet-grid-footer mr-1 pl-2 pr-2">{{ round.betradarSport.name | translate }}</span>
      </div>
    </header>
    <!-- end mobile round header -->

    <!-- Beggin desktop round header -->
    <header class="card-header d-flex flex-column mobile-hide desktop-round-header" [id]="'heading' + i"
      data-toggle="collapse" [attr.data-target]="'#collapse' + i" [attr.aria-controls]="'collapse' + i"
      (click)="selectRound(round.uuid4, null); scrollTo('heading' + i)">
      <div class="d-flex w-100">
        <div class="p-0 round-date-box w-100" style="text-align: center; background: #181717; font-size: 15px;">
          <span> {{ round.startDateTimeFormatted.split(" ")[0] }} </span>
          <span> {{ round.startDateTimeFormatted.split(" ")[1] }} </span>
        </div>
        <div class="d-flex w-100 flex-column round-box"
          style="font-size: large; font-family: Arial, Helvetica, sans-serif;">
          <div class="rounds-info d-flex w-100">
            <span *ngIf="!round.singleMatchRound && !round.jiuJitsuRound"
              class="round-name d-inline-flex align-items-center" style="width: 65%;">
              <div class="img-container mr-1">
                <div class="zoomImg">
                  <img width="60px" src="{{round.homeShield}}"
                    onerror="onerror=null; src='https://poolbet365.com/assets/shields/default-logo.png'">
                </div>
                <img width="20px" src="{{round.homeShield}}"
                  onerror="onerror=null; src='https://poolbet365.com/assets/shields/default-logo.png'" class="baseImg">
              </div>
              {{ round.name }}
            </span>

            <span
              *ngIf="round.singleMatchRound && round.jiuJitsuRound && round.betradarSport && round.betradarSportId != 21"
              class="round-name d-inline-flex align-items-center" style="width: 65%;">
              <div class="img-container mr-1">
                <div class="zoomImg">
                  <img width="60px" src="{{round.homeShield}}"
                    onerror="onerror=null; src='https://poolbet365.com/assets/shields/default-logo.png'">
                </div>
                <img width="20px" src="{{round.homeShield}}"
                  onerror="onerror=null; src='https://poolbet365.com/assets/shields/default-logo.png'" class="baseImg">
              </div>
              {{ round.name }}
              <div class="img-container ml-1">
                <div class="zoomImg">
                  <img width="60px" src="{{round.awayShield}}"
                    onerror="onerror=null; src='https://poolbet365.com/assets/shields/default-logo.png'">
                </div>
                <img width="20px" src="{{round.awayShield}}"
                  onerror="onerror=null; src='https://poolbet365.com/assets/shields/default-logo.png'" class="baseImg">
              </div>
            </span>


            <span *ngIf="round.singleMatchRound && round.jiuJitsuRound  && round.betradarSportId == 21"
              class="round-name d-inline-flex align-items-center" style="width: 65%;">
              <div class="img-container mr-1">
                <div class="zoomImg">
                  <img width="60px" src="{{round.homeShield}}"
                    onerror="onerror=null; src='https://poolbet365.com/assets/shields/default-logo.png'">
                </div>
                <img width="20px" src=" {{round.homeShield}}"
                  onerror="onerror=null; src='https://poolbet365.com/assets/shields/default-logo.png'" class="baseImg">
              </div>
              {{ round.homeTeam }} ({{"Home" | translate}}) X {{round.awayTeam}} ({{"Away" | translate}})
              <div class="img-container ml-1">
                <div class="zoomImg">
                  <img width="60px" src="{{round.awayShield}}"
                    onerror="onerror=null; src='https://poolbet365.com/assets/shields/default-logo.png'">
                </div>
                <img width="20px" src="{{round.awayShield}}"
                  onerror="onerror=null; src='https://poolbet365.com/assets/shields/default-logo.png'" class="baseImg">
              </div>
            </span>

            <div class="d-flex prizes-box" style="width: 35%; justify-content: start; gap: 3%; padding-right: 2%;">
              <span class="round-prize d-inline-flex" style="width: 30%;">{{ AdjustCurrency(round.value)}}</span>
              <span class="round-premium d-inline-flex" style="width: 40%;">{{
                AdjustCurrency(round.prizes[1].gathered)
                }}</span>
              <span class="round-jackpot d-inline-flex justify-content-start" style="width: 40%;">
                {{ AdjustCurrency(round.prizes[0].gathered) }}</span>
            </div>
          </div>
          <div class="rounds-details d-flex justify-content-between">
            <div class="d-flex">
              <span class="bet-grid-footer d-flex align-items-center mr-1 pl-2 pr-2">{{"Round" | translate}} {{
                round.number }}</span>
              <span class="bet-grid-footer d-flex align-items-center mr-1 pl-2 pr-2">{{"events" | translate}}
                {{round.matches?.length || round.events?.length}}</span>
              <!-- <span class="bet-grid-footer mr-1 pl-2 pr-2">
                        <i class='fas fa-ticket-alt mr-1' style='font-size: 12px;'></i> {{ round.totalBets | number:'1.0-0' }}
                      </span> -->
              <span class="bet-grid-footer d-flex align-items-center mr-1 pl-2 pr-2">{{ round.betradarSport.name |
                translate }}</span>
            </div>
            <div class="mr-4">
              <app-recovered-jackpot-details class="bet-grid-footer d-flex align-items-center mr-1 pl-2 pr-2"
                [currentRound]="round.id"></app-recovered-jackpot-details>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- End desktop round header -->

    <!-- Beggin round options -->
    <div [id]="'collapse' + round.uuid4" class="collapse" [class.show]="openedCollapses[round.uuid4]"
      [attr.aria-labelledby]="'heading' + round.uuid4" data-parent="#accordionExample">
      <div class="mobile-info-box desktop-hide">
        <button (click)="ticketListMobileOpen()" class="couponList-btn">
          {{ 'pg-tickets.tickets-list' | translate }}
        </button>
        <app-recovered-jackpot-details class="jackpotDetails-btn"
          [currentRound]="round.id"></app-recovered-jackpot-details>
      </div>
      <div *ngIf="!round.jiuJitsuRound && !round.singleMatchRound">
        <div *ngFor="let match of round.matches; let j = index"
          class="card-body p-0 d-flex flex-column justify-content-center w-100"
          [ngClass]="j % 2 === 0 ? 'even-stripe' : 'odd-stripe'" style="color: white; font-size: 15px;">
          <!-- <app-recovered-jackpot-details class="jackpotDetails-btn"
            [currentRound]="round.id"></app-recovered-jackpot-details> -->
          <ng-container *ngIf="!round.rankRound">
            <div class="d-flex align-items-center p-2" style="font-size: 12px;">
              <div class="col-2 d-flex align-items-center justify-content-center championshipRoundGameDate">
                {{ match.dateTime | date: 'dd/MM' }} <br />
                {{ match.dateTime | date: 'shortTime' }}
              </div>
              <div class="col-5 d-flex flex-column justify-content-center championshipRoundTeams">
                <span>
                  <img class="teams-logo"
                    onerror="this.onerror=null;this.src='https://poolbet365.com/assets/shields/default-logo.png';"
                    src="{{getMatchShield(match.homeName)}}" width="20px">
                  {{ match.homeName }}
                </span>
                <span>
                  <img class="teams-logo"
                    onerror="this.onerror=null;this.src='https://poolbet365.com/assets/shields/default-logo.png';"
                    src="{{getMatchShield(match.awayName)}}" width="20px">
                  {{ match.awayName }}
                </span>
              </div>
              <div class="normal-round-options championshipRoundOptions col-5 d-flex">
                <button type="button" class="bet-btn" (click)="setBet(j, '1'); setXY($event);"
                  [class.bg-yellow]="formatingCheck(j, '1')">
                  <i [ngClass]="{'hidden' : showBlink(j) }" class="fa fa-play warning-arrow" aria-hidden="true"></i>
                  1
                </button>
                <button type="button" class="bet-btn" (click)="setBet(j, 'x'); setXY($event);"
                  [class.bg-yellow]="formatingCheck(j, 'x')">
                  <i [ngClass]="{'hidden' : showBlink(j) }" class="fa fa-play warning-arrow" aria-hidden="true"></i>
                  X
                </button>
                <button type="button" class="bet-btn" (click)="setBet(j, '2'); setXY($event);"
                  [class.bg-yellow]="formatingCheck(j, '2')">
                  <i [ngClass]="{'hidden' : showBlink(j) }" class="fa fa-play warning-arrow" aria-hidden="true"></i>
                  2
                </button>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="round.rankRound">
            <div class="d-flex p-2" [ngClass]="j % 2 === 0 ? 'even-stripe' : 'odd-stripe'">
              <div class="col-4 pl-0 d-flex align-items-center justify-content-center">
                <span style="color: #ef772d;">{{getSelectedRank(match.id)}}</span>
              </div>
              <div class="d-flex align-items-center justify-content-start col-5 pl-0">
                <img class="mr-1" src="{{getFormula1Shield(match.homeName)}}" width="20px"
                  onerror="onerror=null;src='https://poolbet365.com/assets/shields/default-logo.png'" /> {{
                match.homeName }}
              </div>
              <div class="normal-round-options col-3 p-0">
                <button type="button" class="bet-btn"
                  (click)="rankCheck(match.roundId, currentRank.toString()); setBet(match.id, currentRank.toString()); setXY($event)"
                  id="{{i}}c" [class.bg-yellow]="formatingCheck(match.id, currentRank.toString())">
                  {{ getSelectedRank(match.id) !== '-' ? getSelectedRank(match.id) : currentRank }}°
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div *ngIf="round.jiuJitsuRound && round.singleMatchRound">
        <div *ngFor="let event of round.events; let j = index"
          class="card-body p-0 d-flex flex-column justify-content-center w-100"
          [ngClass]="j % 2 === 0 ? 'even-stripe' : 'odd-stripe'" style="color: white; font-size: 15px;">
          <!-- <app-recovered-jackpot-details class="jackpotDetails-btn"
            [currentRound]="round.id"></app-recovered-jackpot-details> -->
          <ng-container>
            <div class="d-flex p-2" [ngClass]="j % 2 === 0 ? 'even-stripe' : 'odd-stripe'">
              <div class="d-flex align-items-center smOptions col-6 pl-0">{{ event.name | translate }}</div>


              <div *ngIf="event.type == 0" class="normal-round-options col-6 p-0">
                <button type="button" class="bet-btn smOverBtn" (click)="setBet(j, '1'); setXY($event);"
                  [class.bg-yellow]="formatingCheck(j, '1')">
                  <i [ngClass]="{'hidden' : showBlink(j) }" class="fa fa-play warning-arrow" aria-hidden="true"></i>
                  1
                </button>
                <button type="button" class="bet-btn" (click)="setBet(j, 'x'); setXY($event);"
                  [class.bg-yellow]="formatingCheck(j, 'x')">
                  <i [ngClass]="{'hidden' : showBlink(j) }" class="fa fa-play warning-arrow" aria-hidden="true"></i>
                  X
                </button>
                <button type="button" class="bet-btn smUnderBtn" (click)="setBet(j, '2'); setXY($event);"
                  [class.bg-yellow]="formatingCheck(j, '2')">
                  <i [ngClass]="{'hidden' : showBlink(j) }" class="fa fa-play warning-arrow" aria-hidden="true"></i>
                  2
                </button>
              </div>

              <div *ngIf="event.type == 1" class="normal-round-options col-6 p-0">
                <button type="button" class="bet-btn smOverBtn" (click)="setBet(j, '1'); setXY($event);"
                  [class.bg-yellow]="formatingCheck(j, '1')">
                  <i [ngClass]="{'hidden' : showBlink(j) }" class="fa fa-play warning-arrow" aria-hidden="true"></i>
                  1
                </button>

                <button type="button" class="bet-btn smUnderBtn" (click)="setBet(j, '2'); setXY($event);"
                  [class.bg-yellow]="formatingCheck(j, '2')">
                  <i [ngClass]="{'hidden' : showBlink(j) }" class="fa fa-play warning-arrow" aria-hidden="true"></i>
                  2
                </button>
              </div>

              <div *ngIf="event.type == 2" class="normal-round-options col-6 p-0 d-flex align-items-center">
                <button type="button" class="bet-btn smOverBtn" (click)="setBet(j, '1'); setXY($event);"
                  [class.bg-yellow]="formatingCheck(j, '1')">
                  <i [ngClass]="{'hidden' : showBlink(j) }" class="fa fa-play warning-arrow" aria-hidden="true"></i>
                  {{ 'Over' | translate}}
                </button>
                <span class="odd-view"> {{event.odd}} </span>
                <button type="button" class="bet-btn smUnderBtn" (click)="setBet(j, '2'); setXY($event);"
                  [class.bg-yellow]="formatingCheck(j, '2')">
                  <i [ngClass]="{'hidden' : showBlink(j) }" class="fa fa-play warning-arrow" aria-hidden="true"></i>
                  {{ 'Under' | translate}}
                </button>
              </div>

              <div *ngIf="event.type == 3" class="normal-round-options col-6 p-0 d-flex align-items-center">
                <button type="button" class="bet-btn smOverBtn" (click)="setBet(j, '1'); setXY($event);"
                  [class.bg-yellow]="formatingCheck(j, '1')">
                  <i [ngClass]="{'hidden' : showBlink(j) }" class="fa fa-play warning-arrow" aria-hidden="true"></i>
                  {{ 'Yes' | translate}}
                </button>
                <button type="button" class="bet-btn smUnderBtn" (click)="setBet(j, '2'); setXY($event);"
                  [class.bg-yellow]="formatingCheck(j, '2')">
                  <i [ngClass]="{'hidden' : showBlink(j) }" class="fa fa-play warning-arrow" aria-hidden="true"></i>
                  {{ 'No' | translate}}
                </button>
              </div>

              <div *ngIf="event.type == 4" class="normal-round-options col-6 p-0 d-flex align-items-center">
                <button type="button" class="bet-btn smOverBtn" (click)="setBet(j, '1'); setXY($event);"
                  [class.bg-yellow]="formatingCheck(j, '1')">
                  <i [ngClass]="{'hidden' : showBlink(j) }" class="fa fa-play warning-arrow" aria-hidden="true"></i>
                  {{ 'Even' | translate}}
                </button>
                <button type="button" class="bet-btn smUnderBtn" (click)="setBet(j, '2'); setXY($event);"
                  [class.bg-yellow]="formatingCheck(j, '2')">
                  <i [ngClass]="{'hidden' : showBlink(j) }" class="fa fa-play warning-arrow" aria-hidden="true"></i>
                  {{ 'Odd' | translate}}
                </button>
              </div>

              <div *ngIf="event.type == 5" class="normal-round-options col-6 p-0">
                <button type="button" class="bet-btn smOverBtn" (click)="setBet(j, '1'); setXY($event);"
                  [class.bg-yellow]="formatingCheck(j, '1')">
                  <i [ngClass]="{'hidden' : showBlink(j) }" class="fa fa-play warning-arrow" aria-hidden="true"></i>
                  {{ 'Home' | translate}}
                </button>

                <button type="button" class="bet-btn smUnderBtn" (click)="setBet(j, '2'); setXY($event);"
                  [class.bg-yellow]="formatingCheck(j, '2')">
                  <i [ngClass]="{'hidden' : showBlink(j) }" class="fa fa-play warning-arrow" aria-hidden="true"></i>
                  {{ 'Away' | translate}}
                </button>
              </div>

              <div *ngIf="event.type == 6" class="normal-round-options col-6 p-0">
                <button type="button" class="bet-btn smOverBtn" (click)="setBet(j, '1'); setXY($event);"
                  [class.bg-yellow]="formatingCheck(j, '1')">
                  <i [ngClass]="{'hidden' : showBlink(j) }" class="fa fa-play warning-arrow" aria-hidden="true"></i>
                  {{ 'Home' | translate}}
                </button>

                <button type="button" class="bet-btn" (click)="setBet(j, 'x'); setXY($event);"
                  [class.bg-yellow]="formatingCheck(j, 'x')">
                  <i [ngClass]="{'hidden' : showBlink(j) }" class="fa fa-play warning-arrow" aria-hidden="true"></i>
                  {{ 'Draw' | translate}}
                </button>

                <button type="button" class="bet-btn smUnderBtn" (click)="setBet(j, '2'); setXY($event);"
                  [class.bg-yellow]="formatingCheck(j, '2')">
                  <i [ngClass]="{'hidden' : showBlink(j) }" class="fa fa-play warning-arrow" aria-hidden="true"></i>
                  {{ 'Away' | translate}}
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- End round options -->

  </section>
</main>