<!--DESKTOP RESULT PAGE-->
<div class="container" style="border-radius: 3px; ">
  <div class="row">
    <!-- Card -->
    <div class="card w-100 bg-theme">

      <!-- Card content -->
      <div class="card-body bg-theme" *ngIf="!isLoaded" id="spinner-loading">
        <!-- Loading (desapears when is loaded) -->
        <div class="justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>

      <div class="card-body p-0">
        <div class="col-12 pt-3 overflow-hidden mobile-hide" style="width: 100vw;">
          <div class="col-12 d-flex align-items-end">
            <div class="col-6">
              <h3 class="m-0 resultTitle">
                <i class="fas fa-trophy" style="font-size: 25px;"></i> {{ 'pg-result.result' | translate }}
              </h3>
            </div>
            <div class="col-6 text-right">
              <button routerLink="/event-result" class="tertiary-button backBtn">{{ 'pg-home.back' | translate
                }}</button>
            </div>
          </div>
          <hr class="mt-3" />
          <ng-container *ngIf="!isLoadingGames && games.length > 0">
            <div class="d-flex">
              <div class="col-7 pl-0">

                <div
                  class="newDesign-background-price rounded-box-header d-flex flex-column align-items-center justify-content-center">
                  <div *ngIf="!this.singleMatch && !this.jiuJitsuMatch">
                    <p class="m-0 text-center"> <img width="65px"
                        *ngIf="!currentRound.singleMatchRound && !currentRound.jiuJitsuRound" class="mr-1"
                        src="{{getChampionshipLogos(this.nameEvent)}}"
                        onerror="onerror=null;src='https://poolbet365.com/assets/shields/default-logo.png'">{{this.nameEvent}}
                    </p>
                  </div>
                  <div *ngIf="this.singleMatch == 1">
                    <p class="m-0 text-center"> <img width="40px" class="mr-1" src="{{getShield(this.nameEvent,0)}}"
                        onerror="onerror=null;src='https://poolbet365.com/assets/shields/default-logo.png'">{{this.nameEvent}}
                      <img width="40px" class="mr-1" src="{{getShield(this.nameEvent,1)}}"
                        onerror="onerror=null;src='https://poolbet365.com/assets/shields/default-logo.png'">
                    </p>
                  </div>
                  <p style="font-size: 16px;" class="m-0">{{this.dateEnd | date:'mediumDate'}}</p>

                  <span style="font-size: 16px; cursor: pointer; color: #6790c7; text-decoration: underline;"
                    class="m-0" (click)="openBetList()">{{'Participants' | translate}}</span>
                  <!-- <span style="font-size: 16px; cursor: pointer; color: #6790c7; text-decoration: underline;" class="m-0" (click)="openBetList()">{{countBets}} {{'Participants' | translate}}</span> -->
                </div>

                <div class="newDesign-background-price mt-1 d-flex justify-content-between align-items-center">
                  <h4 class="center-content center-content-title"
                    style=" color: rgb(255, 255, 255); font-weight: 400; margin:0;">{{
                    'pg-result.prize-paid' | translate }}:
                  </h4>
                  <h2 class="center-content price-text">
                    {{ Currency.currencySymbol }} {{totalPrize * Currency.quote | currency:'':''}}
                  </h2>
                </div>

                <div>
                  <div class="d-flex">
                    <div class="w-50 mt-1 mr-1 newDesign-background-price rounded-box-header text-left">
                      {{'prize amount' | translate}}:
                      <h4 class="price-text">
                        {{ Currency.currencySymbol }} {{prizeAmount * Currency.quote | currency:'':''}}
                      </h4>
                    </div>
                    <div class="col-6 mt-1 newDesign-background-price rounded-box-header">
                      {{'accumulated value' | translate}}:
                      <h4 class="price-text">
                        {{ Currency.currencySymbol }} {{JackpotValue * Currency.quote | currency:'':''}}
                      </h4>
                    </div>
                  </div>

                  <div class="d-flex">
                    <div class="w-50 mt-1 mr-1 newDesign-background-price rounded-box-header text-left">{{
                      'pg-result.total-winners' | translate }}:
                      <h4 class="price-text">{{winners.length}} {{ 'pg-jurisdiction.users' | translate }}</h4>
                    </div>
                    <div class="col-6 mt-1 newDesign-background-price rounded-box-header">{{ 'pg-result.number-hits' |
                      translate }}:
                      <h4 class="price-text">{{eachHits}} {{ 'pg-result.hits' | translate }}</h4>
                    </div>
                  </div>

                  <div class="d-flex">
                    <div class="w-50 d-flex flex-column mr-1 newDesign-background-price rounded-box-header mt-1">
                      {{ 'pg-result.winnings-coupons' | translate }}:
                      <!-- <h4 class="mobile-price-text">{{this.quantityCoupons}} {{ 'pg-tickets.tickets' | translate}}</h4> -->
                      <h4 class="mobile-price-text">{{this.quantityCoupons}} {{ 'pg-tickets.tickets' | translate}}</h4>
                    </div>
                    <div
                      class=" col-6 newDesign-background-price d-flex flex-wrap mt-1 align-items-center justify-content-between">
                      <!-- <div class="rounded-box-header">{{ 'pg-result.value-for-each' | translate }}</div> -->
                      <div class="rounded-box-header">{{ 'pg-result.value-for-each' | translate }}:</div>
                      <h4 class="price-text">{{ Currency.currencySymbol }} {{eachValue * Currency.quote |
                        currency:'':''}}
                      </h4>
                    </div>
                  </div>
                </div>

                <div class="mat-elevation-z8 bg-paginator mt-2" style="overflow: hidden;">
                  <table mat-table [dataSource]="dataSource">
                    <!-- Position Column -->
                    <ng-container matColumnDef="betId">
                      <th mat-header-cell class="winners-header" *matHeaderCellDef> {{ 'pg-result.ticket' | translate }}
                      </th>
                      <td mat-cell class="winners-row coupom-code" *matCellDef="let element"
                        (click)="detailCoupon(element)"> {{element.betId.slice(0,8)}} </td>
                    </ng-container>
                    <!-- Name Column -->
                    <!-- <ng-container matColumnDef="admin">
                      <th mat-header-cell class="winners-header" *matHeaderCellDef> {{ 'pg-result.operator' | translate }}
                      </th>
                      <td mat-cell class="winners-row" *matCellDef="let element"> {{element.admin}} </td>
                    </ng-container> -->
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="player">
                      <th mat-header-cell class="winners-header" *matHeaderCellDef> {{ 'pg-result.players' | translate
                        }}
                      </th>
                      <td mat-cell class="winners-row" *matCellDef="let element">
                        <span class="top-text">{{element.player}}</span>
                    </ng-container>
                    <ng-container matColumnDef="country">
                      <th mat-header-cell class="winners-header" *matHeaderCellDef> {{ 'pg-settings.country' | translate
                        }}
                      </th>
                      <td mat-cell class="winners-row" *matCellDef="let element">{{element.country | translate}}</td>
                    </ng-container>
                    <!-- <ng-container matColumnDef="city">
                      <th mat-header-cell class="winners-header" *matHeaderCellDef> {{ 'pg-settings.city' | translate }}
                      </th>
                      <td mat-cell class="winners-row" *matCellDef="let element"> {{element.city}} </td>
                    </ng-container> -->
                    <ng-container matColumnDef="quantity">
                      <th mat-header-cell class="winners-header text-center" *matHeaderCellDef>
                        {{ 'pg-home.quantity-tickets-value' | translate }} </th>
                      <td mat-cell class="winners-row" *matCellDef="let element" style="text-align: center;">
                        {{ element.quantity }} </td>
                    </ng-container>
                    <ng-container matColumnDef="value">
                      <th mat-header-cell class="winners-header" *matHeaderCellDef>
                        {{ 'pg-home.total-value' | translate }}
                      </th>
                      <td mat-cell class="winners-row text-center p-0" *matCellDef="let element"> {{element.value}}
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                  <mat-paginator #dataPaginator class="bg-paginator" [pageSize]="5" [pageSizeOptions]="[5, 10, 25]"
                    showFirstLastButtons></mat-paginator>
                </div>
              </div>

              <div class="col-5 p-0 pb-3" style="overflow: hidden;">
                <div class="col-12 p-0">
                  <div class="bottom-match">
                    <div class="result-header col-12 d-flex">
                      <p class="mb-0">{{ 'pg-result.result' | translate }}</p>
                    </div>
                    <div *ngIf="currentRound.rankRound && !currentRound.singleMatchRound" class="table-striped"
                      scrollX="true" maxWidth="100%" style="color: white;" [innerHtml]="TableResult">
                    </div>

                    <div *ngIf="currentRound.rankRound && currentRound.singleMatchRound && currentRound.jiuJitsuRound"
                      class="table table-striped" scrollX="true" maxWidth="100%" style="color: white;"
                      [innerHtml]="TableResult">
                    </div>

                    <div *ngIf="!currentRound.rankRound && !currentRound.singleMatchRound" class="" scrollX="true"
                      maxWidth="100%" style="min-width: 0px; color: white; font-size: 16px;" [innerHtml]="TableResult">
                    </div>

                    <table *ngIf="currentRound.jiuJitsuRound && !currentRound.singleMatchRound"
                      class="table-striped p-5" scrollX="true" maxWidth="100%"
                      style="min-width: 0px; color: white; font-size: 16px;" [innerHtml]="TableResult">
                      <tr>
                        <td class="border-space" style="text-align: left;">
                          {{'Winner' | translate}}
                        </td>
                        <td class="pr-2 goals text-right"><img class="pl-2" width="15px"
                            src="../../assets/svgs/green-icon.svg">
                        </td>
                        <td class="border-space w-25" style="text-align: center;">
                          <span
                            class="goals font-weight-light text-white border-space p-0">{{jiuJitsuMatchResult[0]}}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="border-space" style="text-align: left;">
                          {{'Advantages difference' | translate}}
                        </td>
                        <td class="pr-2 goals text-right">
                          <div class="d-flex justify-content-end">{{ this.games[0].advantagesDifference }}<img
                              class="pl-2" width="15px" src="../../assets/svgs/green-icon.svg"></div>
                        </td>
                        <td class="border-space w-25" style="text-align: center;">
                          <span
                            class="goals font-weight-light text-white border-space p-0">{{jiuJitsuMatchResult[1]}}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="border-space" style="text-align: left;">
                          {{'Winner points' | translate}}
                        </td>
                        <td class="pr-2 goals text-right">{{ this.games[0].winnerPoints }}<img class="pl-2" width="15px"
                            src="../../assets/svgs/green-icon.svg">
                        </td>
                        <td class="border-space w-25" style="text-align: center;">
                          <span
                            class="goals font-weight-light text-white border-space p-0">{{jiuJitsuMatchResult[2]}}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="border-space" style="text-align: left;">
                          {{'Loser points' | translate}}
                        </td>
                        <td class="pr-2 goals text-right">{{ this.games[0].loserPoints }}<img class="pl-2" width="15px"
                            src="../../assets/svgs/green-icon.svg">
                        </td>
                        <td class="border-space w-25" style="text-align: center;">
                          <span
                            class="goals font-weight-light text-white border-space p-0">{{jiuJitsuMatchResult[3]}}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="border-space" style="text-align: left;">
                          {{'Final points difference' | translate}}
                        </td>
                        <td class="pr-2 goals text-right">{{ this.games[0].pointsDifference }}<img class="pl-2"
                            width="15px" src="../../assets/svgs/green-icon.svg">
                        </td>
                        <td class="border-space w-25" style="text-align: center;">
                          <span
                            class="goals font-weight-light text-white border-space p-0">{{jiuJitsuMatchResult[4]}}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="border-space" style="text-align: left;">
                          {{'Finishing at minute' | translate}}
                        </td>
                        <td class="pr-2 goals text-right">{{ this.games[0].finishingAtMinute }}<img class="pl-2"
                            width="15px" src="../../assets/svgs/green-icon.svg">
                        </td>
                        <td class="border-space w-25" style="text-align: center;">
                          <span
                            class="goals font-weight-light text-white border-space p-0">{{jiuJitsuMatchResult[5]}}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="border-space" style="text-align: left;">
                          {{'Win by finishing' | translate}}
                        </td>
                        <td class="pr-2 goals text-right"><img class="pl-2" width="15px"
                            src="../../assets/svgs/green-icon.svg">
                        </td>
                        <td class="border-space w-25" style="text-align: center;">
                          <span
                            class="goals font-weight-light text-white border-space p-0">{{jiuJitsuMatchResult[6]}}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="border-space" style="text-align: left;">
                          {{'Draw in points' | translate}}
                        </td>
                        <td class="pr-2 goals text-right"><img class="pl-2" width="15px"
                            src="../../assets/svgs/green-icon.svg">
                        </td>
                        <td class="border-space w-25" style="text-align: center;">
                          <span
                            class="goals font-weight-light text-white border-space p-0">{{jiuJitsuMatchResult[7]}}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="border-space" style="text-align: left;">
                          {{'Will be punishment' | translate}}
                        </td>
                        <td class="pr-2 goals text-right"><img class="pl-2" width="15px"
                            src="../../assets/svgs/green-icon.svg">
                        </td>
                        <td class="border-space w-25" style="text-align: center;">
                          <span
                            class="goals font-weight-light text-white border-space p-0">{{jiuJitsuMatchResult[8]}}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="border-space" style="text-align: left;">
                          {{'Will be disqualified' | translate}}
                        </td>
                        <td class="pr-2 goals text-right"><img class="pl-2" width="15px"
                            src="../../assets/svgs/green-icon.svg">
                        </td>
                        <td class="border-space w-25" style="text-align: center;">
                          <span
                            class="goals font-weight-light text-white border-space p-0">{{jiuJitsuMatchResult[9]}}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="border-space" style="text-align: left;">
                          {{'Neck finishing' | translate}}
                        </td>
                        <td class="pr-2 goals text-right"><img class="pl-2" width="15px"
                            src="../../assets/svgs/green-icon.svg">
                        </td>
                        <td class="border-space w-25" style="text-align: center;">
                          <span
                            class="goals font-weight-light text-white border-space p-0">{{jiuJitsuMatchResult[10]}}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="border-space" style="text-align: left;">
                          {{'Arm finishing' | translate}}
                        </td>
                        <td class="pr-2 goals text-right"><img class="pl-2" width="15px"
                            src="../../assets/svgs/green-icon.svg">
                        </td>
                        <td class="border-space w-25" style="text-align: center;">
                          <span
                            class="goals font-weight-light text-white border-space p-0">{{jiuJitsuMatchResult[11]}}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="border-space" style="text-align: left;">
                          {{'Leg finishing' | translate}}
                        </td>
                        <td class="pr-2 goals text-right"><img class="pl-2" width="15px"
                            src="../../assets/svgs/green-icon.svg">
                        </td>
                        <td class="border-space w-25" style="text-align: center;">
                          <span
                            class="goals font-weight-light text-white border-space p-0">{{jiuJitsuMatchResult[12]}}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="border-space" style="text-align: left;">
                          {{'Fall points' | translate}}
                        </td>
                        <td class="pr-2 goals text-right"><img class="pl-2" width="15px"
                            src="../../assets/svgs/green-icon.svg">
                        </td>
                        <td class="border-space w-25" style="text-align: center;">
                          <span
                            class="goals font-weight-light text-white border-space p-0">{{jiuJitsuMatchResult[13]}}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="border-space" style="text-align: left;">
                          {{'Sweep points' | translate}}
                        </td>
                        <td class="pr-2 goals text-right"><img class="pl-2" width="15px"
                            src="../../assets/svgs/green-icon.svg">
                        </td>
                        <td class="border-space w-25" style="text-align: center;">
                          <span
                            class="goals font-weight-light text-white border-space p-0">{{jiuJitsuMatchResult[14]}}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="border-space" style="text-align: left;">
                          {{'Guard points' | translate}}
                        </td>
                        <td class="pr-2 goals text-right"><img class="pl-2" width="15px"
                            src="../../assets/svgs/green-icon.svg">
                        </td>
                        <td class="border-space w-25" style="text-align: center;">
                          <span
                            class="goals font-weight-light text-white border-space p-0">{{jiuJitsuMatchResult[15]}}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="border-space" style="text-align: left;">
                          {{'Mount points' | translate}}
                        </td>
                        <td class="pr-2 goals text-right"><img class="pl-2" width="15px"
                            src="../../assets/svgs/green-icon.svg">
                        </td>
                        <td class="border-space w-25" style="text-align: center;">
                          <span
                            class="goals font-weight-light text-white border-space p-0">{{jiuJitsuMatchResult[16]}}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="border-space" style="text-align: left;">
                          {{'Knee on belly points' | translate}}
                        </td>
                        <td class="pr-2 goals text-right"><img class="pl-2" width="15px"
                            src="../../assets/svgs/green-icon.svg">
                        </td>
                        <td class="border-space w-25" style="text-align: center;">
                          <span
                            class="goals font-weight-light text-white border-space p-0">{{jiuJitsuMatchResult[17]}}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="border-space" style="text-align: left;">
                          {{'Grip on back points' | translate}}
                        </td>
                        <td class="pr-2 goals text-right"><img class="pl-2" width="15px"
                            src="../../assets/svgs/green-icon.svg">
                        </td>
                        <td class="border-space w-25" style="text-align: center;">
                          <span
                            class="goals font-weight-light text-white border-space p-0">{{jiuJitsuMatchResult[18]}}</span>
                        </td>
                      </tr>
                    </table>

                  </div>
                </div>
              </div>

            </div>
          </ng-container>
        </div>
      </div>

      <!-- Card -->

      <!--MOBILE RESULT PAGE-->
      <ng-container *ngIf="!isLoadingGames && games.length > 0">
        <div class="col-12 pt-3 pb-3 overflow-hidden desktop-hide">
          <div class="col-12 d-flex align-items-end">
            <div class="col-7 p-0">
              <h3 class="m-0 resultTitle"><i class="fas fa-trophy" style="font-size: 25px;"></i> {{ 'pg-result.result' |
                translate }}</h3>
            </div>
            <div class="col-5 text-right">
              <button routerLink="/event-result" class="tertiary-button backBtn">{{ 'pg-home.back' | translate
                }}</button>
            </div>
          </div>
          <hr class="mb-1" />
          <div id="spinner-loading2">
            <div class="col-12" *ngIf="isLoadingGames">
              <!-- Loading (desapears when is loaded) -->
              <div class="justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex" *ngIf="(!isLoadingGames && games.length > 0)">
            <!--            <p class="mt-3" *ngIf="isLoaded">-->
            <!--              {{ 'pg-tickets.round' | translate }}: {{currentRound.name}} - {{currentRound.number}}-->
            <!--              ({{ currentRound.startDateTime | date: 'dd/MM/yy'}} - {{currentRound.endDateTime | date: 'dd/MM/yy'}})-->
            <!--            </p>-->
            <div class="col-12 pl-0 pr-0">
              <div
                class="newDesign-background-price rounded-box-header d-flex flex-column align-items-center justify-content-center">
                <div *ngIf="this.singleMatch == 0">
                  <p class="m-0 text-center"> <img width="40px" *ngIf="!currentRound.singleMatchRound" class="mr-1"
                      src="{{getChampionshipLogos(this.nameEvent)}}" alt="">
                    {{this.nameEvent}}
                  </p>
                </div>
                <div *ngIf="this.singleMatch == 1">
                  <p class="m-0 text-center"> <img width="40px" class="mr-1" src="{{getShield(this.nameEvent,0)}}"
                      onerror="src='https://poolbet365.com/assets/shields/default-logo.png'">{{this.nameEvent}}
                    <img width="40px" class="mr-1" src="{{getShield(this.nameEvent,1)}}"
                      onerror="src='https://poolbet365.com/assets/shields/default-logo.png'">
                  </p>
                </div>
                <p style="font-size: 16px;" class="m-0">{{this.dateEnd | date:'mediumDate'}}</p>
              </div>
              <div class="d-flex align-items-center">
                <div class="w-50 mt-1 mr-1 newDesign-background-price mobile-rounded-box-header text-left">
                  {{ 'pg-tickets.totalBets' | translate }}
                  <h4 class=" mobile-price-text">
                    {{countBets}} {{'pg-tickets.tickets' | translate}}
                  </h4>
                </div>
                <div class="col-6 mt-1 newDesign-background-price mobile-rounded-box-header">
                  {{'pg-result.prize-paid' | translate }}:
                  <h4 class=" mobile-price-text">
                    {{ Currency.currencySymbol }} {{totalPrize * Currency.quote | currency:'':''}}
                  </h4>
                </div>
              </div>
              <div>
                <div class="d-flex align-items-center">
                  <div class="w-50 mt-1 mr-1 newDesign-background-price mobile-rounded-box-header text-left">
                    {{ 'pg-result.total-winners' | translate }}:
                    <h4 class="mobile-price-text">{{winners.length}} {{ 'pg-jurisdiction.users' | translate }}</h4>
                  </div>
                  <!-- <div class="title-div">
                    <h4>{{winners.length}}</h4>
                  </div> -->
                  <div class="col-6 mt-1 newDesign-background-price mobile-rounded-box-header">
                    {{ 'pg-result.number-hits' | translate }}:
                    <h4 class="mobile-price-text">{{eachHits}} {{ 'pg-result.hits' | translate }}</h4>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="w-50 d-flex flex-column mr-1 newDesign-background-price mt-1">
                    <div class="mobile-rounded-box-header">{{ 'pg-result.winnings-coupons' | translate }}: </div>
                    <h4 class="mobile-price-text">{{this.quantityCoupons}} {{ 'pg-tickets.tickets' | translate}}</h4>
                  </div>
                  <div class="col-6 newDesign-background-price d-flex flex-column mt-1">
                    <div class="mobile-rounded-box-header">{{ 'pg-result.value-for-each' | translate }}:</div>
                    <h4 class="mobile-price-text">{{ Currency.currencySymbol }} {{eachValue * Currency.quote |
                      currency:'':''}}</h4>
                  </div>
                </div>
              </div>
              <div class="mat-elevation-z8 bg-paginator mt-3" style="overflow: hidden;">
                <table mat-table [dataSource]="dataSource" style="min-width:0px !important;">
                  <!-- Position Column -->
                  <ng-container matColumnDef="betId">
                    <th mat-header-cell class="winners-header p-0 text-center" *matHeaderCellDef> {{ 'pg-result.ticket'
                      |
                      translate }}
                    </th>
                    <td mat-cell class="winners-row coupom-code text-center pl-0 p-1" *matCellDef="let element"
                      (click)="detailCoupon(element)"> {{element.betId.slice(0,8)}} </td>
                  </ng-container>
                  <!-- Name Column -->
                  <!-- <ng-container matColumnDef="admin">
                    <th mat-header-cell class="winners-header" *matHeaderCellDef> {{ 'pg-result.operator' | translate }}
                    </th>
                    <td mat-cell class="winners-row" *matCellDef="let element"> {{element.admin}} </td>
                  </ng-container> -->
                  <!-- Symbol Column -->
                  <ng-container matColumnDef="player">
                    <th mat-header-cell class="winners-header text-center" *matHeaderCellDef> {{ 'pg-result.players' |
                      translate }}
                    </th>
                    <td mat-cell class="winners-row p-1" *matCellDef="let element"> <span
                        class="top-text">{{element.player}}</span></td>
                  </ng-container>
                  <ng-container matColumnDef="country">
                    <th mat-header-cell class="winners-header p-1" *matHeaderCellDef> {{ 'pg-settings.country' |
                      translate
                      }}
                    </th>
                    <td mat-cell class="winners-row" *matCellDef="let element"> <span
                        class="top-text">{{element.country | translate}}</span> <br> {{element.city}} </td>
                  </ng-container>
                  <ng-container matColumnDef="quantity">
                    <th mat-header-cell class="winners-header text-center" *matHeaderCellDef> {{
                      'pg-home.quantity-tickets-value' |
                      translate }} </th>
                    <td mat-cell class="winners-row" *matCellDef="let element" style="text-align: center;">
                      {{element.quantity}} </td>
                  </ng-container>
                  <ng-container matColumnDef="value">
                    <th mat-header-cell class="winners-header p-1" *matHeaderCellDef> {{ 'pg-home.total-value' |
                      translate
                      }}
                    </th>
                    <td mat-cell class="winners-row text-center p-0" *matCellDef="let element"> {{element.value}} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator #dataPaginator class="bg-paginator" [pageSize]="5" [pageSizeOptions]="[5, 10, 25]"
                  showFirstLastButtons></mat-paginator>
              </div>
              <div class="col-12 p-0">
                <div class="bottom-match overflow-hidden">
                  <div class="result-header col-12 d-flex">
                    <div class="col-7">
                      <p class="mb-0 p-1">{{ 'pg-result.result' | translate }}</p>
                    </div>
                    <div class="col-5 ml-3 align-items-center d-flex justify-content-around">
                      <p class="mb-0" *ngIf="currentRound.singleMatchRound">Casa</p>
                      <p class="mb-0" *ngIf="currentRound.singleMatchRound">Fora</p>
                    </div>
                  </div>
                  <table *ngIf="currentRound.rankRound && !currentRound.singleMatchRound"
                    class="table table-striped white-text" mdbTable scrollX="true" maxWidth="100%"
                    style="min-width: 0px; color: white;">
                    <tr *ngFor="let player of rankResult; let i = index" style="color: white;">
                      <ng-container *ngIf="sportCategory == 40;else poker">
                        <td style="text-align: left; width: 60%;" class="border-space"><span
                            class="ml-4 mr-2 p-1 f1-shield"><img
                              src="https://poolbet365.com/assets/shields/{{getFormula1Shield(player)}}"
                              width="20px"></span>{{player}}</td>
                      </ng-container>
                      <ng-template #poker>
                        <td style="text-align: left; width: 60%;" class="border-space"><span
                            class="ml-4 mr-2 p-1 f1-shield"><img
                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/Naipe_ouros.png/216px-Naipe_ouros.png"
                              width="20px"></span>{{player}}</td>
                      </ng-template>
                      <ng-template #default>
                        <td style="text-align: left; width: 60%;" class="border-space"><span
                            class="ml-4 mr-2 p-1 f1-shield"><img src="../../../assets/shields/default-logo.png"
                              width="20px"></span>{{player}}</td>
                      </ng-template>
                      <td class="border-space text-right p-0 rankRound-adjust" style="padding: 0px !important;"><span
                          class="goals">{{i + 1}}° </span></td>
                      <td style="padding: 0px !important;"><img class="pl-2" width="14px"
                          src="../../assets/svgs/green-icon.svg"></td>
                    </tr>
                  </table>

                  <table
                    *ngIf="!currentRound.rankRound && !currentRound.singleMatchRound && !currentRound.jiuJitsuRound"
                    class="table table-striped table-adjust" mdbTable mdbTableScroll scrollX="true" maxWidth="100%"
                    style="min-width: 0px; color: white;">
                    <tr *ngFor="let game of games; let i = index">
                      <td width="60%" class="border-space-mobile" style="text-align: left;">
                        <span><img class="mr-1" src="https://poolbet365.com/assets/shields/{{game.homeName}}.png"
                            onerror="this.src='https://poolbet365.com/assets/shields/default-logo.png';"
                            width="17px">{{game.homeName}}</span>
                        <br>
                        <span><img class="mr-1" src="https://poolbet365.com/assets/shields/{{game.awayName}}.png"
                            onerror="this.src='https://poolbet365.com/assets/shields/default-logo.png';"
                            width="17px">{{game.awayName}}</span>
                      </td>
                      <td class="pr-2 goals align-items-center text-right">{{result(game.homeTeamScore,
                        game.awayTeamScore)}}<img class="pl-2" width="14px" src="../../assets/svgs/green-icon.svg">
                      </td>
                      <td class="border-space-mobile">
                        <span class="goals font-weight-light text-white">{{game.homeTeamScore}}</span>
                        <br>
                        <span class="goals font-weight-light text-white">{{game.awayTeamScore}}</span>
                      </td>
                    </tr>
                  </table>

                  <table *ngIf="!currentRound.rankRound && currentRound.singleMatchRound && resultLength == 14"
                    class="table-striped p-5" scrollX="true" maxWidth="100%"
                    style="min-width: 0px; color: white; font-size: 16px;">
                    <tr>
                      <td class="border-space" style="text-align: left;">
                        {{'Final Result' | translate}}
                      </td>
                      <td class="pr-2 goals text-center">{{result(games[0].homeTeamScore, games[0].awayTeamScore) |
                        translate}}<img class="pl-2" width="14px" src="../../assets/svgs/green-icon.svg">
                      </td>
                      <td class="border-space " style="text-align: center;">
                        <span
                          class="goals font-weight-light text-white border-space p-0">{{games[0].homeTeamScore}}</span>
                      </td>
                      <td class="text-center border-space"><span
                          class="goals font-weight-light text-white border-space p-0">{{games[0].awayTeamScore}}</span>
                      </td>
                    </tr>

                    <tr>
                      <td class="border-space" style="text-align: left;">
                        {{'More Ball Possession' | translate}}
                      </td>
                      <td class="pr-2 goals text-center">
                        {{resultForSingleMatch(games[0].moreBallPossession.split(',')[0],
                        games[0].moreBallPossession.split(',')[1])}}
                        <img class="pl-2" width="14px" src="../../assets/svgs/green-icon.svg">
                      </td>
                      <td class="col-2 border-space" style="text-align: center;"> <span
                          class="goals font-weight-light text-white border-space p-0">{{games[0].moreBallPossession.split(',')[0]}}%</span>
                      </td>
                      <td class="col-2 border-space text-center"><span
                          class="goals font-weight-light text-white border-space p-0">{{games[0].moreBallPossession.split(',')[1]}}%</span>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="2" class="border-space" style="text-align: left;">
                        {{'Total Goals' | translate}} ({{games[0].totalGoals}})
                      </td>
                      <td colspan="2" class="goals border-space text-center">
                        {{resultOverUnder(games[0].matchWinner.split('|')[2])}}</td>
                    </tr>

                    <tr>
                      <td colspan="2" class="border-space" style="text-align: left;">
                        {{'Total Corners' | translate}} ({{games[0].totalCorners}})
                      </td>
                      <td colspan="2" class="goals border-space text-center">
                        {{resultOverUnder(games[0].matchWinner.split('|')[3])}}</td>
                    </tr>

                    <tr>
                      <td colspan="2" class="border-space" style="text-align: left;">
                        {{'Yellow Cards' | translate}} ({{games[0].yellowCards}})
                      </td>
                      <td colspan="2" class="goals border-space text-center">
                        {{resultOverUnder(games[0].matchWinner.split('|')[4])}}</td>
                    </tr>

                    <tr>
                      <td colspan="2" class="border-space" style="text-align: left;">
                        {{'Corners Home Team' | translate}} ({{games[0].cornerHomeTeam}})
                      </td>
                      <td colspan="2" class="goals border-space text-center">
                        {{resultOverUnder(games[0].matchWinner.split('|')[5])}}
                      </td>
                    </tr>

                    <tr>
                      <td colspan="2" class="border-space" style="text-align: left;">
                        {{'Corners Away Team' | translate}} ({{games[0].cornerAwayTeam}})
                      </td>
                      <td colspan="2" class="goals border-space text-center">
                        {{resultOverUnder(games[0].matchWinner.split('|')[6])}}
                      </td>
                    </tr>

                    <tr>
                      <td colspan="2" class="border-space" style="text-align: left;">
                        {{'Total Faults' | translate}} ({{games[0].totalFaults}})
                      </td>
                      <td colspan="2" class="goals border-space text-center">
                        {{resultOverUnder(games[0].matchWinner.split('|')[7])}}</td>
                    </tr>

                    <tr>
                      <td colspan="2" class="border-space" style="text-align: left;">
                        {{'Shoots on Target' | translate}} ({{games[0].shootsOnTarget}})
                      </td>
                      <td colspan="2" class="goals border-space text-center">
                        {{resultOverUnder(games[0].matchWinner.split('|')[8])}}</td>
                    </tr>

                    <tr>
                      <td colspan="2" class="border-space" style="text-align: left;">
                        {{'Will be Penalty' | translate}}
                      </td>
                      <td colspan="2" class="goals border-space text-uppercase text-center">
                        {{resultYesNo(games[0].penaltyOnFavor.split(',')[0],
                        games[0].penaltyOnFavor.split(',')[1])}}</td>
                    </tr>

                    <tr>
                      <td colspan="2" class="border-space" style="text-align: left;">
                        {{'Goal in the 1st Half' | translate}}
                      </td>
                      <td colspan="2" class="goals border-space text-uppercase text-center">
                        {{resultYesNo(games[0].goalIn1stHalf.split(',')[0],
                        games[0].goalIn1stHalf.split(',')[1])}}</td>
                    </tr>

                    <tr>
                      <td colspan="2" class="border-space" style="text-align: left;">
                        {{'Goal in the 2nd Half' | translate}}
                      </td>
                      <td colspan="2" class="goals border-space text-uppercase text-center">
                        {{resultYesNo(games[0].goalIn2ndHalf.split(',')[0],
                        games[0].goalIn2ndHalf.split(',')[1])}}</td>
                    </tr>

                    <tr>
                      <td colspan="2" class="border-space" style="text-align: left;">
                        {{'GG (Both Score)' | translate}}
                      </td>
                      <td colspan="2" class="goals border-space text-uppercase text-center">
                        {{resultGG(games[0].homeTeamScore, games[0].awayTeamScore)}}
                      </td>
                    </tr>

                    <tr>
                      <td colspan="2" class="border-space" style="text-align: left;">
                        {{'Red Card' | translate}}
                      </td>
                      <td colspan="2" class="goals border-space text-uppercase text-center">
                        {{resultYesNo(games[0].redCards.split(',')[0],
                        games[0].redCards.split(',')[1])}}</td>
                    </tr>
                  </table>

                  <table *ngIf="currentRound.rankRound && currentRound.singleMatchRound" class="table-striped p-5"
                    scrollX="true" maxWidth="100%" style="min-width: 0px; color: white; font-size: 16px;"
                    [innerHTML]="TableResult"> </table>

                  <table
                    *ngIf="!currentRound.rankRound && currentRound.singleMatchRound && currentRound.jiuJitsuRound && resultLength == 18"
                    class="table-striped p-5" scrollX="true" maxWidth="100%"
                    style="min-width: 0px; color: white; font-size: 16px;" [innerHTML]="TableResult"></table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
