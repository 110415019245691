import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, HostBinding, NgZone } from '@angular/core';
import { AppComponent } from '../app.component';
import { ConfigService } from '../config.service';
import { Round } from '../round.interface';
import { Game } from '../game.interface';
import { ResponseGame } from '../response-game.class';
import { Prize } from '../prize.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../user.interface';
import { InteractionService } from '../interaction.service';
import { TranslateService } from '@ngx-translate/core';
import { Banner } from '../banner.interface';
import { Slides } from '../slide.interface';
import { Currency } from '../currency.interface';
import axios from 'axios';
import { Ticket } from '../ticket.interface';
import { ElementRef } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Sort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { BetradarSport } from '../betradar-sport.interface';
import { ModalDirective } from 'angular-bootstrap-md';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatSidenavContainer } from '@angular/material';
import { MediaMatcher } from '@angular/cdk/layout';

// @ts-ignore
import football from '../../assets/image/sports/football.png';
// @ts-ignore
import basketball from '../../assets/image/sports/basketball.png';
// @ts-ignore
import horse from '../../assets/image/sports/horse.png';
// @ts-ignore
import pingpong from '../../assets/image/sports/ping-pong.png';
// @ts-ignore
import tennis from '../../assets/image/sports/tennis.png';
import { Console, debug } from 'console';
import { ModalsService } from '../services/modals/modals.service';
import { ISkinConfig } from '../Interfaces/SkinConfig.interface';

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  const CHECK_INTERVALL = 1000 // in ms
}

export interface PeriodicElement {
  id: string;
  datetime: string;
  sport: string;
  bolao: string;
  value: string;
  prize: string;
  jackpot: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class HomeComponent implements OnInit {

  @ViewChild('frame3', { static: true }) modalRegister: ModalDirective;
  @ViewChild('frameMessageDanger', { static: true }) modalMessagesDanger: ModalDirective; // REMOVIDO
  @ViewChild('frameMessage', { static: true }) modalMessages: ModalDirective; // REMOVIDO
  @ViewChild('roundmenu', { static: true }) roundMenu: MatSidenavContainer;
  @ViewChild('sidemenu', { static: true }) sideMenu: MatSidenavContainer;

  slidesPathPrefix = "http://poolbet365.com/assets/banners/";
  //slidesPathPrefix = "http://localhost/assets/banners/" //local test
  topSlideList: Slides[] = [];

  registerLoading = false;
  userValidatingForm: FormGroup;
  forgotValidatingForm: FormGroup;
  validatingForm: FormGroup;

  mobileQuery: MediaQueryList;
  showCloseBtn: boolean = false;
  showCloseRoundBtn: boolean = false;
  msgToAdd: string = '';
  titleToAdd: string = '';
  msgType: string[] = ['', 'modal-primary', 'modal-warning', 'modal-success', 'modal-danger'];
  isLogged: boolean = false;
  isLogin: string = localStorage.getItem('currentUser');

  // datatable
  dataSource: PeriodicElement[];
  columnsToDisplay = ['id', 'datetime', 'sport', 'bolao', 'value', 'prize', 'jackpot'];
  expandedElement: PeriodicElement | null;

  round: Round;
  rounds: Round[];

  games: Game[];
  newGame: Game;
  betradarSports: BetradarSport[];

  bannersList: Banner[] = [];
  user: User = this.appComponent.userAdmin;
  isLoaded: boolean = false;
  isChecked: boolean = false;
  idRound: number;
  dateBegin: string;
  dateEnd: string;
  responseGames = new Array<ResponseGame>();
  prizes: Prize[] = [];
  jackpot: number;
  prizeAmount: number;
  fullPrize: number;
  roundValue: number;
  currentTicket = {} as Ticket;
  rankTypeRound: boolean = false;
  singleMatchRound: boolean = false;
  currentRank: number = 1;
  rankArray: number[] = [0];
  Currency: Currency = <Currency>{};
  username: string;
  code: string;
  icons = [
    { icon: 'football.png' },
    { icon: 'football.png' },
    { icon: 'football.png' },
    { icon: 'football.png' },
    { icon: 'football.png' },
    { icon: 'football.png' },
    { icon: 'football.png' },
    { icon: 'football.png' },
    { icon: 'football.png' },
    { icon: 'football.png' },
    { icon: 'football.png' },
    { icon: 'football.png' },
    { icon: 'football.png' },
    { icon: 'football.png' },
    { icon: 'football.png' },
    { icon: 'ping-pong.png' },
    { icon: 'tennis.png' },
    { icon: 'tennis.png' },
  ];
  message = this.translate.instant('pg-home.required-results');
  private roundName: any;
  lastAction: number;

  skinConfig: ISkinConfig = <ISkinConfig>{};

  private _mobileQueryListener: () => void;

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  hubId: number;
  constructor(private interactionService: InteractionService, private configService: ConfigService,
    private appComponent: AppComponent, private router: Router,
    private modalsService: ModalsService,
    private translate: TranslateService, private elRef: ElementRef,
    private _formBuilder: FormBuilder, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
    private datePipe: DatePipe,
    private ngZone: NgZone,
    private route: ActivatedRoute
  ) {

    this.interactionService.isLogged.subscribe(logged => {
      this.isLogged = logged;
    })
    this.translate.setDefaultLang(this.appComponent.activeLang);
    this.mobileQuery = media.matchMedia('(max-width: 989px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.initListener();

    this.hubId = this.configService.creatorId;
    interactionService.skinConfig
      .subscribe(data => {
        this.skinConfig = data;
      })
  }
  check() {
    throw new Error('Method not implemented.');
  }
  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
    });
  }
  reset() {
    this.lastAction = Date.now();
  }
  initInterval() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    // console.log("home")
    // this.code = this.route.snapshot.paramMap.get("code");
    // this.interactionService.setRefCode(this.code ?? '')

    // if (this.code == null) localStorage.setItem('openRegister', "false");
    // else {
    //   localStorage.setItem('refCode', this.code);
    //   localStorage.setItem('openRegister', "true");
    // } REMOVER


    //this.getPrizeList();
    this.configService.recvSelLan.subscribe((param: string) => {
      let curUserId = Number(localStorage.getItem('currentUser'));
      if (curUserId <= 0) {
        curUserId = 1;
      }
      this.getOwnTopSlides(curUserId, this.configService.getLanguageId(param), "top");
    });


    this.validatingForm = new FormGroup({
      loginFormModalName: new FormControl('', Validators.required),
      loginFormModalPassword: new FormControl('', Validators.required)
    });

    this.userValidatingForm = new FormGroup({
      userFormLogin: new FormControl('', Validators.required),
      userFormName: new FormControl('', Validators.required),
      userFormLastName: new FormControl(''),
      userFormEmail: new FormControl('', Validators.required),
      userFormConfirmEmail: new FormControl('', Validators.required),
      userJurisdictionCoupon: new FormControl(''),
      userFormCountry: new FormControl(''),
      userFormCity: new FormControl('')
    });

    this.forgotValidatingForm = new FormGroup({
      forgotFormEmail: new FormControl('', [Validators.required, Validators.email])
    });

    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });

    this.getMasterParent();

    // this.interactionService.homeVisibleRound
    //   .subscribe(round => {
    //     if (round != null) {
    //       // this.setRound(round);
    //     }
    //   });

    this.getLastActivatedRound();

    this.interactionService.ticketPanelState
      .subscribe(data => {
        if (data != null) {
          this.currentTicket = data;
        } else
          this.initRound();
      });

    this.interactionService.matchRankArray
      .subscribe(data => {
        if (data != null) {
          this.rankArray = data;
          this.currentRank = 1;
          for (let index = 1; index < this.rankArray.length + 1; index++) {
            if (!this.rankArray[index]) {
              this.currentRank = index;
              break;
            }
          }
        }
      });
  }

  changeActivatedRound(element: PeriodicElement) {
    let currentRound = this.rounds.find(x => x.uuid4 == element.id);
    this.configService.getRound(currentRound.id)
      .subscribe(data => {
        this.interactionService.setHomeVisibleRound(data);
      }, error => {
        console.log(error);
      });
  }

  getRoundId(length) {
    var result = '';
    var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  // getPrizeList() {
  //   this.configService.getPrize().subscribe(data => {
  //     data.forEach(element => {
  //       this.prizes.push({
  //         id: element.id,
  //         gathered: element.gathered,
  //         paidOn: element.paidOn,
  //         roundId: element.roundId,
  //         type: element.type
  //       })
  //     })
  //   });
  // }


  setPrizes() {
    this.jackpot = (this.prizes.find(x => x.roundId == this.round.id && x.type == 2).gathered);

    this.prizeAmount = (this.prizes.find(x => x.roundId == this.round.id && x.type == 1).gathered);

    this.fullPrize = (+(this.jackpot) + +(this.prizeAmount));

    this.roundValue = this.round.value;
  }

  setRoundPrizes(round) {
    var jackpot = (this.prizes.find(x => x.roundId == round.id && x.type == 2).gathered);

    var prizeAmount = (this.prizes.find(x => x.roundId == round.id && x.type == 1).gathered);

    var fullPrize = (+(jackpot) + +(prizeAmount));

    var roundValue = round.value;

    return [jackpot, prizeAmount, fullPrize, roundValue];
  }

  getLastActivatedRound() {
    this.configService.getLastActivatedRound()
      .subscribe(data => {
        if (data != null) {
          //this.getPrize();
          this.interactionService.setHomeVisibleRound(data);
          this.setRound(data);
        } else {
          let spinner = document.getElementById('spinner-loading');
          if (spinner != null) {
            spinner.classList.add('hidden');
          }
          this.isLoaded = true;
        }

      }, error => {
        console.log(error);
      });
  }

  setRound(round: Round) {
    this.round = round;
    let localDateTime = (new Date(this.round.startDateTime)).getTime() - (new Date()).getTimezoneOffset() * 60000;
    this.round.startDateTime = new Date(localDateTime);
    localDateTime = (new Date(this.round.endDateTime)).getTime() - (new Date()).getTimezoneOffset() * 60000;
    this.round.endDateTime = new Date(localDateTime);
    this.getGames();
    //this.getOwnBanners();
    //this.getMasterParent();
    //this.getPrize();
  }

  getMasterParent() {
    let userId = 1;
    if (!this.appComponent.userAdmin) {
      userId = 1;
    } else {
      userId = this.appComponent.userAdmin.id;
    }

    let curUserId = Number(localStorage.getItem('currentUser'));
    if (curUserId <= 0) {
      curUserId = 1;
    }
    let activeLang;
    if (localStorage.getItem('language') == null) {
      activeLang = 'pt';
    } else {
      activeLang = localStorage.getItem('language');
    }
    this.getOwnTopSlides(curUserId, this.configService.getLanguageId(activeLang), "top");
  }

  getOwnTopSlides(masterParentId: number, languageId: number, slideType: string) {
    this.configService.getOwnSlides(this.hubId, languageId, slideType)
      .subscribe(data => {
        this.topSlideList = data;
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant('pg-banners.operation-fail'), this.translate.instant('pg-banners.load-fail-info'), true)
        console.log(error);
      });
  }

  getGames() {
    this.configService.getGames(this.round.id)
      .subscribe(async data => {
        this.games = data;
        for (let i = 0; data && i < this.games.length; i++) {
          const gameDateTime = (new Date(this.games[i].dateTime)).getTime() - (new Date()).getTimezoneOffset() * 60000;
          this.games[i].dateTime = new Date(gameDateTime);
        }
        this.singleMatchRound = this.round.singleMatchRound ? true : false;
        if (this.singleMatchRound && false) {

          this.games[0].homeName = "Final Result",

            this.games.push({ // More Ball Possession 2
              id: this.games[0].id + 2,
              awayName: "",
              awayTeamScore: this.games[0].awayTeamScore,
              homeTeamScore: this.games[0].homeTeamScore,
              betradarMatchId: this.games[0].betradarMatchId,
              cornerAwayTeam: this.games[0].cornerAwayTeam,
              cornerHomeTeam: this.games[0].cornerHomeTeam,
              goalIn1stHalf: this.games[0].goalIn1stHalf,
              goalIn2ndHalf: this.games[0].goalIn2ndHalf,
              matchResult: this.games[0].matchResult,
              moreBallPossession: this.games[0].moreBallPossession,
              penaltyOnFavor: this.games[0].penaltyOnFavor,
              redCards: this.games[0].redCards,
              roundId: this.games[0].roundId,
              shootsOnTarget: this.games[0].shootsOnTarget,
              status: this.games[0].status,
              totalCorners: this.games[0].totalCorners,
              totalFaults: this.games[0].totalFaults,
              totalGoals: this.games[0].totalGoals,
              yellowCards: this.games[0].yellowCards,
              homeName: "More Ball Possession",
              two_options: 0,
              dateTime: this.games[0].dateTime,
              matchWinner: this.games[0].matchWinner,
              goalsHomeTeam: this.games[0].goalsHomeTeam,
              goalsAwayTeam: this.games[0].goalsAwayTeam,
              shootsOnTargetHomeTeam: this.games[0].shootsOnTargetHomeTeam,
              shootsOnTargetAwayTeam: this.games[0].shootsOnTargetAwayTeam
            });

          this.games.push({ // Total Goals 3
            id: this.games[0].id + 3,
            awayName: "",
            awayTeamScore: this.games[0].awayTeamScore,
            homeTeamScore: this.games[0].homeTeamScore,
            betradarMatchId: this.games[0].betradarMatchId,
            cornerAwayTeam: this.games[0].cornerAwayTeam,
            cornerHomeTeam: this.games[0].cornerHomeTeam,
            goalIn1stHalf: this.games[0].goalIn1stHalf,
            goalIn2ndHalf: this.games[0].goalIn2ndHalf,
            matchResult: this.games[0].matchResult,
            moreBallPossession: this.games[0].moreBallPossession,
            penaltyOnFavor: this.games[0].penaltyOnFavor,
            redCards: this.games[0].redCards,
            roundId: this.games[0].roundId,
            shootsOnTarget: this.games[0].shootsOnTarget,
            status: this.games[0].status,
            totalCorners: this.games[0].totalCorners,
            totalFaults: this.games[0].totalFaults,
            totalGoals: this.games[0].totalGoals,
            yellowCards: this.games[0].yellowCards,
            homeName: "Total Goals",
            two_options: 0,
            dateTime: this.games[0].dateTime,
            matchWinner: this.games[0].matchWinner,
            goalsHomeTeam: this.games[0].goalsHomeTeam,
            goalsAwayTeam: this.games[0].goalsAwayTeam,
            shootsOnTargetHomeTeam: this.games[0].shootsOnTargetHomeTeam,
            shootsOnTargetAwayTeam: this.games[0].shootsOnTargetAwayTeam
          });

          this.games.push({ // Goals Home Team 4
            id: this.games[0].id + 4,
            awayName: "",
            awayTeamScore: this.games[0].awayTeamScore,
            homeTeamScore: this.games[0].homeTeamScore,
            betradarMatchId: this.games[0].betradarMatchId,
            cornerAwayTeam: this.games[0].cornerAwayTeam,
            cornerHomeTeam: this.games[0].cornerHomeTeam,
            goalIn1stHalf: this.games[0].goalIn1stHalf,
            goalIn2ndHalf: this.games[0].goalIn2ndHalf,
            matchResult: this.games[0].matchResult,
            moreBallPossession: this.games[0].moreBallPossession,
            penaltyOnFavor: this.games[0].penaltyOnFavor,
            redCards: this.games[0].redCards,
            roundId: this.games[0].roundId,
            shootsOnTarget: this.games[0].shootsOnTarget,
            status: this.games[0].status,
            totalCorners: this.games[0].totalCorners,
            totalFaults: this.games[0].totalFaults,
            totalGoals: this.games[0].totalGoals,
            yellowCards: this.games[0].yellowCards,
            homeName: "Goals Home Team",
            two_options: 0,
            dateTime: this.games[0].dateTime,
            matchWinner: this.games[0].matchWinner,
            goalsHomeTeam: this.games[0].goalsHomeTeam,
            goalsAwayTeam: this.games[0].goalsAwayTeam,
            shootsOnTargetHomeTeam: this.games[0].shootsOnTargetHomeTeam,
            shootsOnTargetAwayTeam: this.games[0].shootsOnTargetAwayTeam
          });

          this.games.push({ // Goals Away Team 5
            id: this.games[0].id + 5,
            awayName: "",
            awayTeamScore: this.games[0].awayTeamScore,
            homeTeamScore: this.games[0].homeTeamScore,
            betradarMatchId: this.games[0].betradarMatchId,
            cornerAwayTeam: this.games[0].cornerAwayTeam,
            cornerHomeTeam: this.games[0].cornerHomeTeam,
            goalIn1stHalf: this.games[0].goalIn1stHalf,
            goalIn2ndHalf: this.games[0].goalIn2ndHalf,
            matchResult: this.games[0].matchResult,
            moreBallPossession: this.games[0].moreBallPossession,
            penaltyOnFavor: this.games[0].penaltyOnFavor,
            redCards: this.games[0].redCards,
            roundId: this.games[0].roundId,
            shootsOnTarget: this.games[0].shootsOnTarget,
            status: this.games[0].status,
            totalCorners: this.games[0].totalCorners,
            totalFaults: this.games[0].totalFaults,
            totalGoals: this.games[0].totalGoals,
            yellowCards: this.games[0].yellowCards,
            homeName: "Goals Away Team",
            two_options: 0,
            dateTime: this.games[0].dateTime,
            matchWinner: this.games[0].matchWinner,
            goalsHomeTeam: this.games[0].goalsHomeTeam,
            goalsAwayTeam: this.games[0].goalsAwayTeam,
            shootsOnTargetHomeTeam: this.games[0].shootsOnTargetHomeTeam,
            shootsOnTargetAwayTeam: this.games[0].shootsOnTargetAwayTeam
          });

          this.games.push({ // Total Corners 6
            id: this.games[0].id + 6,
            awayName: "",
            awayTeamScore: this.games[0].awayTeamScore,
            homeTeamScore: this.games[0].homeTeamScore,
            betradarMatchId: this.games[0].betradarMatchId,
            cornerAwayTeam: this.games[0].cornerAwayTeam,
            cornerHomeTeam: this.games[0].cornerHomeTeam,
            goalIn1stHalf: this.games[0].goalIn1stHalf,
            goalIn2ndHalf: this.games[0].goalIn2ndHalf,
            matchResult: this.games[0].matchResult,
            moreBallPossession: this.games[0].moreBallPossession,
            penaltyOnFavor: this.games[0].penaltyOnFavor,
            redCards: this.games[0].redCards,
            roundId: this.games[0].roundId,
            shootsOnTarget: this.games[0].shootsOnTarget,
            status: this.games[0].status,
            totalCorners: this.games[0].totalCorners,
            totalFaults: this.games[0].totalFaults,
            totalGoals: this.games[0].totalGoals,
            yellowCards: this.games[0].yellowCards,
            homeName: "Total Corners",
            two_options: 0,
            dateTime: this.games[0].dateTime,
            matchWinner: this.games[0].matchWinner,
            goalsHomeTeam: this.games[0].goalsHomeTeam,
            goalsAwayTeam: this.games[0].goalsAwayTeam,
            shootsOnTargetHomeTeam: this.games[0].shootsOnTargetHomeTeam,
            shootsOnTargetAwayTeam: this.games[0].shootsOnTargetAwayTeam
          });

          this.games.push({ // Corners Home Team 7
            id: this.games[0].id + 7,
            awayName: "",
            awayTeamScore: this.games[0].awayTeamScore,
            homeTeamScore: this.games[0].homeTeamScore,
            betradarMatchId: this.games[0].betradarMatchId,
            cornerAwayTeam: this.games[0].cornerAwayTeam,
            cornerHomeTeam: this.games[0].cornerHomeTeam,
            goalIn1stHalf: this.games[0].goalIn1stHalf,
            goalIn2ndHalf: this.games[0].goalIn2ndHalf,
            matchResult: this.games[0].matchResult,
            moreBallPossession: this.games[0].moreBallPossession,
            penaltyOnFavor: this.games[0].penaltyOnFavor,
            redCards: this.games[0].redCards,
            roundId: this.games[0].roundId,
            shootsOnTarget: this.games[0].shootsOnTarget,
            status: this.games[0].status,
            totalCorners: this.games[0].totalCorners,
            totalFaults: this.games[0].totalFaults,
            totalGoals: this.games[0].totalGoals,
            yellowCards: this.games[0].yellowCards,
            homeName: "Corners Home Team",
            two_options: 0,
            dateTime: this.games[0].dateTime,
            matchWinner: this.games[0].matchWinner,
            goalsHomeTeam: this.games[0].goalsHomeTeam,
            goalsAwayTeam: this.games[0].goalsAwayTeam,
            shootsOnTargetHomeTeam: this.games[0].shootsOnTargetHomeTeam,
            shootsOnTargetAwayTeam: this.games[0].shootsOnTargetAwayTeam
          });

          this.games.push({ // Corners Away Team 8
            id: this.games[0].id + 8,
            awayName: "",
            awayTeamScore: this.games[0].awayTeamScore,
            homeTeamScore: this.games[0].homeTeamScore,
            betradarMatchId: this.games[0].betradarMatchId,
            cornerAwayTeam: this.games[0].cornerAwayTeam,
            cornerHomeTeam: this.games[0].cornerHomeTeam,
            goalIn1stHalf: this.games[0].goalIn1stHalf,
            goalIn2ndHalf: this.games[0].goalIn2ndHalf,
            matchResult: this.games[0].matchResult,
            moreBallPossession: this.games[0].moreBallPossession,
            penaltyOnFavor: this.games[0].penaltyOnFavor,
            redCards: this.games[0].redCards,
            roundId: this.games[0].roundId,
            shootsOnTarget: this.games[0].shootsOnTarget,
            status: this.games[0].status,
            totalCorners: this.games[0].totalCorners,
            totalFaults: this.games[0].totalFaults,
            totalGoals: this.games[0].totalGoals,
            yellowCards: this.games[0].yellowCards,
            homeName: "Corners Away Team",
            two_options: 0,
            dateTime: this.games[0].dateTime,
            matchWinner: this.games[0].matchWinner,
            goalsHomeTeam: this.games[0].goalsHomeTeam,
            goalsAwayTeam: this.games[0].goalsAwayTeam,
            shootsOnTargetHomeTeam: this.games[0].shootsOnTargetHomeTeam,
            shootsOnTargetAwayTeam: this.games[0].shootsOnTargetAwayTeam
          });

          this.games.push({ // Shoots on Target 9
            id: this.games[0].id + 9,
            awayName: "",
            awayTeamScore: this.games[0].awayTeamScore,
            homeTeamScore: this.games[0].homeTeamScore,
            betradarMatchId: this.games[0].betradarMatchId,
            cornerAwayTeam: this.games[0].cornerAwayTeam,
            cornerHomeTeam: this.games[0].cornerHomeTeam,
            goalIn1stHalf: this.games[0].goalIn1stHalf,
            goalIn2ndHalf: this.games[0].goalIn2ndHalf,
            matchResult: this.games[0].matchResult,
            moreBallPossession: this.games[0].moreBallPossession,
            penaltyOnFavor: this.games[0].penaltyOnFavor,
            redCards: this.games[0].redCards,
            roundId: this.games[0].roundId,
            shootsOnTarget: this.games[0].shootsOnTarget,
            status: this.games[0].status,
            totalCorners: this.games[0].totalCorners,
            totalFaults: this.games[0].totalFaults,
            totalGoals: this.games[0].totalGoals,
            yellowCards: this.games[0].yellowCards,
            homeName: "Shoots on Target",
            two_options: 0,
            dateTime: this.games[0].dateTime,
            matchWinner: this.games[0].matchWinner,
            goalsHomeTeam: this.games[0].goalsHomeTeam,
            goalsAwayTeam: this.games[0].goalsAwayTeam,
            shootsOnTargetHomeTeam: this.games[0].shootsOnTargetHomeTeam,
            shootsOnTargetAwayTeam: this.games[0].shootsOnTargetAwayTeam
          });

          this.games.push({ // Shoots on Target Home Team 10
            id: this.games[0].id + 10,
            awayName: "",
            awayTeamScore: this.games[0].awayTeamScore,
            homeTeamScore: this.games[0].homeTeamScore,
            betradarMatchId: this.games[0].betradarMatchId,
            cornerAwayTeam: this.games[0].cornerAwayTeam,
            cornerHomeTeam: this.games[0].cornerHomeTeam,
            goalIn1stHalf: this.games[0].goalIn1stHalf,
            goalIn2ndHalf: this.games[0].goalIn2ndHalf,
            matchResult: this.games[0].matchResult,
            moreBallPossession: this.games[0].moreBallPossession,
            penaltyOnFavor: this.games[0].penaltyOnFavor,
            redCards: this.games[0].redCards,
            roundId: this.games[0].roundId,
            shootsOnTarget: this.games[0].shootsOnTarget,
            status: this.games[0].status,
            totalCorners: this.games[0].totalCorners,
            totalFaults: this.games[0].totalFaults,
            totalGoals: this.games[0].totalGoals,
            yellowCards: this.games[0].yellowCards,
            homeName: "Shoots on Target Home Team",
            two_options: 0,
            dateTime: this.games[0].dateTime,
            matchWinner: this.games[0].matchWinner,
            goalsHomeTeam: this.games[0].goalsHomeTeam,
            goalsAwayTeam: this.games[0].goalsAwayTeam,
            shootsOnTargetHomeTeam: this.games[0].shootsOnTargetHomeTeam,
            shootsOnTargetAwayTeam: this.games[0].shootsOnTargetAwayTeam
          });

          this.games.push({ // Shoots on Target Away Team 11
            id: this.games[0].id + 11,
            awayName: "",
            awayTeamScore: this.games[0].awayTeamScore,
            homeTeamScore: this.games[0].homeTeamScore,
            betradarMatchId: this.games[0].betradarMatchId,
            cornerAwayTeam: this.games[0].cornerAwayTeam,
            cornerHomeTeam: this.games[0].cornerHomeTeam,
            goalIn1stHalf: this.games[0].goalIn1stHalf,
            goalIn2ndHalf: this.games[0].goalIn2ndHalf,
            matchResult: this.games[0].matchResult,
            moreBallPossession: this.games[0].moreBallPossession,
            penaltyOnFavor: this.games[0].penaltyOnFavor,
            redCards: this.games[0].redCards,
            roundId: this.games[0].roundId,
            shootsOnTarget: this.games[0].shootsOnTarget,
            status: this.games[0].status,
            totalCorners: this.games[0].totalCorners,
            totalFaults: this.games[0].totalFaults,
            totalGoals: this.games[0].totalGoals,
            yellowCards: this.games[0].yellowCards,
            homeName: "Shoots on Target Away Team",
            two_options: 0,
            dateTime: this.games[0].dateTime,
            matchWinner: this.games[0].matchWinner,
            goalsHomeTeam: this.games[0].goalsHomeTeam,
            goalsAwayTeam: this.games[0].goalsAwayTeam,
            shootsOnTargetHomeTeam: this.games[0].shootsOnTargetHomeTeam,
            shootsOnTargetAwayTeam: this.games[0].shootsOnTargetAwayTeam
          });

          this.games.push({ // Total Faults 12
            id: this.games[0].id + 12,
            awayName: "",
            awayTeamScore: this.games[0].awayTeamScore,
            homeTeamScore: this.games[0].homeTeamScore,
            betradarMatchId: this.games[0].betradarMatchId,
            cornerAwayTeam: this.games[0].cornerAwayTeam,
            cornerHomeTeam: this.games[0].cornerHomeTeam,
            goalIn1stHalf: this.games[0].goalIn1stHalf,
            goalIn2ndHalf: this.games[0].goalIn2ndHalf,
            matchResult: this.games[0].matchResult,
            moreBallPossession: this.games[0].moreBallPossession,
            penaltyOnFavor: this.games[0].penaltyOnFavor,
            redCards: this.games[0].redCards,
            roundId: this.games[0].roundId,
            shootsOnTarget: this.games[0].shootsOnTarget,
            status: this.games[0].status,
            totalCorners: this.games[0].totalCorners,
            totalFaults: this.games[0].totalFaults,
            totalGoals: this.games[0].totalGoals,
            yellowCards: this.games[0].yellowCards,
            homeName: "Total Faults",
            two_options: 0,
            dateTime: this.games[0].dateTime,
            matchWinner: this.games[0].matchWinner,
            goalsHomeTeam: this.games[0].goalsHomeTeam,
            goalsAwayTeam: this.games[0].goalsAwayTeam,
            shootsOnTargetHomeTeam: this.games[0].shootsOnTargetHomeTeam,
            shootsOnTargetAwayTeam: this.games[0].shootsOnTargetAwayTeam
          });

          this.games.push({ // Yellow Cards 13
            id: this.games[0].id + 13,
            awayName: "",
            awayTeamScore: this.games[0].awayTeamScore,
            homeTeamScore: this.games[0].homeTeamScore,
            betradarMatchId: this.games[0].betradarMatchId,
            cornerAwayTeam: this.games[0].cornerAwayTeam,
            cornerHomeTeam: this.games[0].cornerHomeTeam,
            goalIn1stHalf: this.games[0].goalIn1stHalf,
            goalIn2ndHalf: this.games[0].goalIn2ndHalf,
            matchResult: this.games[0].matchResult,
            moreBallPossession: this.games[0].moreBallPossession,
            penaltyOnFavor: this.games[0].penaltyOnFavor,
            redCards: this.games[0].redCards,
            roundId: this.games[0].roundId,
            shootsOnTarget: this.games[0].shootsOnTarget,
            status: this.games[0].status,
            totalCorners: this.games[0].totalCorners,
            totalFaults: this.games[0].totalFaults,
            totalGoals: this.games[0].totalGoals,
            yellowCards: this.games[0].yellowCards,
            homeName: "Yellow Cards",
            two_options: 0,
            dateTime: this.games[0].dateTime,
            matchWinner: this.games[0].matchWinner,
            goalsHomeTeam: this.games[0].goalsHomeTeam,
            goalsAwayTeam: this.games[0].goalsAwayTeam,
            shootsOnTargetHomeTeam: this.games[0].shootsOnTargetHomeTeam,
            shootsOnTargetAwayTeam: this.games[0].shootsOnTargetAwayTeam
          });

          this.games.push({ // Will be penalty 14
            id: this.games[0].id + 14,
            awayName: "",
            awayTeamScore: this.games[0].awayTeamScore,
            homeTeamScore: this.games[0].homeTeamScore,
            betradarMatchId: this.games[0].betradarMatchId,
            cornerAwayTeam: this.games[0].cornerAwayTeam,
            cornerHomeTeam: this.games[0].cornerHomeTeam,
            goalIn1stHalf: this.games[0].goalIn1stHalf,
            goalIn2ndHalf: this.games[0].goalIn2ndHalf,
            matchResult: this.games[0].matchResult,
            moreBallPossession: this.games[0].moreBallPossession,
            penaltyOnFavor: this.games[0].penaltyOnFavor,
            redCards: this.games[0].redCards,
            roundId: this.games[0].roundId,
            shootsOnTarget: this.games[0].shootsOnTarget,
            status: this.games[0].status,
            totalCorners: this.games[0].totalCorners,
            totalFaults: this.games[0].totalFaults,
            totalGoals: this.games[0].totalGoals,
            yellowCards: this.games[0].yellowCards,
            homeName: "Will be Penalty",
            two_options: 1,
            dateTime: this.games[0].dateTime,
            matchWinner: this.games[0].matchWinner,
            goalsHomeTeam: this.games[0].goalsHomeTeam,
            goalsAwayTeam: this.games[0].goalsAwayTeam,
            shootsOnTargetHomeTeam: this.games[0].shootsOnTargetHomeTeam,
            shootsOnTargetAwayTeam: this.games[0].shootsOnTargetAwayTeam
          });

          this.games.push({ // Goal in 1st 15
            id: this.games[0].id + 15,
            awayName: "",
            awayTeamScore: this.games[0].awayTeamScore,
            homeTeamScore: this.games[0].homeTeamScore,
            betradarMatchId: this.games[0].betradarMatchId,
            cornerAwayTeam: this.games[0].cornerAwayTeam,
            cornerHomeTeam: this.games[0].cornerHomeTeam,
            goalIn1stHalf: this.games[0].goalIn1stHalf,
            goalIn2ndHalf: this.games[0].goalIn2ndHalf,
            matchResult: this.games[0].matchResult,
            moreBallPossession: this.games[0].moreBallPossession,
            penaltyOnFavor: this.games[0].penaltyOnFavor,
            redCards: this.games[0].redCards,
            roundId: this.games[0].roundId,
            shootsOnTarget: this.games[0].shootsOnTarget,
            status: this.games[0].status,
            totalCorners: this.games[0].totalCorners,
            totalFaults: this.games[0].totalFaults,
            totalGoals: this.games[0].totalGoals,
            yellowCards: this.games[0].yellowCards,
            homeName: "Goal in the 1st Half",
            two_options: 1,
            dateTime: this.games[0].dateTime,
            matchWinner: this.games[0].matchWinner,
            goalsHomeTeam: this.games[0].goalsHomeTeam,
            goalsAwayTeam: this.games[0].goalsAwayTeam,
            shootsOnTargetHomeTeam: this.games[0].shootsOnTargetHomeTeam,
            shootsOnTargetAwayTeam: this.games[0].shootsOnTargetAwayTeam
          });

          this.games.push({ // Goal in 2nd 16
            id: this.games[0].id + 16,
            awayName: "",
            awayTeamScore: this.games[0].awayTeamScore,
            homeTeamScore: this.games[0].homeTeamScore,
            betradarMatchId: this.games[0].betradarMatchId,
            cornerAwayTeam: this.games[0].cornerAwayTeam,
            cornerHomeTeam: this.games[0].cornerHomeTeam,
            goalIn1stHalf: this.games[0].goalIn1stHalf,
            goalIn2ndHalf: this.games[0].goalIn2ndHalf,
            matchResult: this.games[0].matchResult,
            moreBallPossession: this.games[0].moreBallPossession,
            penaltyOnFavor: this.games[0].penaltyOnFavor,
            redCards: this.games[0].redCards,
            roundId: this.games[0].roundId,
            shootsOnTarget: this.games[0].shootsOnTarget,
            status: this.games[0].status,
            totalCorners: this.games[0].totalCorners,
            totalFaults: this.games[0].totalFaults,
            totalGoals: this.games[0].totalGoals,
            yellowCards: this.games[0].yellowCards,
            homeName: "Goal in the 2nd Half",
            two_options: 1,
            dateTime: this.games[0].dateTime,
            matchWinner: this.games[0].matchWinner,
            goalsHomeTeam: this.games[0].goalsHomeTeam,
            goalsAwayTeam: this.games[0].goalsAwayTeam,
            shootsOnTargetHomeTeam: this.games[0].shootsOnTargetHomeTeam,
            shootsOnTargetAwayTeam: this.games[0].shootsOnTargetAwayTeam
          });

          this.games.push({ // GG 17
            id: this.games[0].id + 17,
            awayName: "",
            awayTeamScore: this.games[0].awayTeamScore,
            homeTeamScore: this.games[0].homeTeamScore,
            betradarMatchId: this.games[0].betradarMatchId,
            cornerAwayTeam: this.games[0].cornerAwayTeam,
            cornerHomeTeam: this.games[0].cornerHomeTeam,
            goalIn1stHalf: this.games[0].goalIn1stHalf,
            goalIn2ndHalf: this.games[0].goalIn2ndHalf,
            matchResult: this.games[0].matchResult,
            moreBallPossession: this.games[0].moreBallPossession,
            penaltyOnFavor: this.games[0].penaltyOnFavor,
            redCards: this.games[0].redCards,
            roundId: this.games[0].roundId,
            shootsOnTarget: this.games[0].shootsOnTarget,
            status: this.games[0].status,
            totalCorners: this.games[0].totalCorners,
            totalFaults: this.games[0].totalFaults,
            totalGoals: this.games[0].totalGoals,
            yellowCards: this.games[0].yellowCards,
            homeName: "GG (Both Score)",
            two_options: 1,
            dateTime: this.games[0].dateTime,
            matchWinner: this.games[0].matchWinner,
            goalsHomeTeam: this.games[0].goalsHomeTeam,
            goalsAwayTeam: this.games[0].goalsAwayTeam,
            shootsOnTargetHomeTeam: this.games[0].shootsOnTargetHomeTeam,
            shootsOnTargetAwayTeam: this.games[0].shootsOnTargetAwayTeam
          });

          this.games.push({ // Red Card 18
            id: this.games[0].id + 18,
            awayName: "",
            awayTeamScore: this.games[0].awayTeamScore,
            homeTeamScore: this.games[0].homeTeamScore,
            betradarMatchId: this.games[0].betradarMatchId,
            cornerAwayTeam: this.games[0].cornerAwayTeam,
            cornerHomeTeam: this.games[0].cornerHomeTeam,
            goalIn1stHalf: this.games[0].goalIn1stHalf,
            goalIn2ndHalf: this.games[0].goalIn2ndHalf,
            matchResult: this.games[0].matchResult,
            moreBallPossession: this.games[0].moreBallPossession,
            penaltyOnFavor: this.games[0].penaltyOnFavor,
            redCards: this.games[0].redCards,
            roundId: this.games[0].roundId,
            shootsOnTarget: this.games[0].shootsOnTarget,
            status: this.games[0].status,
            totalCorners: this.games[0].totalCorners,
            totalFaults: this.games[0].totalFaults,
            totalGoals: this.games[0].totalGoals,
            yellowCards: this.games[0].yellowCards,
            homeName: "Red Card",
            two_options: 1,
            dateTime: this.games[0].dateTime,
            matchWinner: this.games[0].matchWinner,
            goalsHomeTeam: this.games[0].goalsHomeTeam,
            goalsAwayTeam: this.games[0].goalsAwayTeam,
            shootsOnTargetHomeTeam: this.games[0].shootsOnTargetHomeTeam,
            shootsOnTargetAwayTeam: this.games[0].shootsOnTargetAwayTeam
          });
        }

        await this.initRound();
        let spinner = document.getElementById('spinner-loading');
        if (spinner != null) {
          spinner.classList.add('hidden');
        }
        this.isLoaded = true;
      }, error => {
        console.log(error);
      });
  }

  getUser() {
    this.configService.getUser(this.appComponent.userAdmin.id)
      .subscribe(data => {
        this.user = data;
      }, error => {
        console.log(error);
      });
  }

  async initRound() {
    if (!this.round) return;
    this.idRound = this.round.number;
    this.dateBegin = this.round.startDateTime.toString();
    this.dateEnd = this.round.endDateTime.toString();
    this.rankTypeRound = this.round.rankRound ? true : false;
    this.singleMatchRound = this.round.singleMatchRound ? true : false;
    this.currentRank = 1;
    this.rankArray = [0];
    this.interactionService.setMatchRank(this.rankArray);
    await this.generateGames();
  }

  generateGames() {

    this.responseGames = new Array<ResponseGame>();
    if (this.singleMatchRound) {
      if (typeof (this.games) === 'undefined') {
        return;
      }
      for (let i = 0; i < this.games.length; i++) {
        this.responseGames[i] = new ResponseGame();
        this.responseGames[i].idGame = this.games[0].id;
        this.responseGames[i].teamHome = this.games[i].homeName;
        this.responseGames[i].teamVisit = "";
        this.responseGames[i].dateBegin = this.games[0].dateTime;
        this.responseGames[i].hourBegin = this.games[0].dateTime;
        this.responseGames[i].dateEnd = this.games[0].dateTime;
        this.responseGames[i].hourEnd = this.games[0].dateTime;
        if (i == 0 || i == 1) {
          this.responseGames[i].two_options = 0;

        }
        else if (i == 2) {
          this.responseGames[i].totalGoals = this.games[0].totalGoals;
        }
        else if (i == 3) {
          this.responseGames[i].totalGoals = this.games[0].totalCorners;
        }
        else if (i == 4) {
          this.responseGames[i].totalGoals = this.games[0].yellowCards;
        }
        else if (i == 5) {
          this.responseGames[i].totalGoals = this.games[0].cornerHomeTeam;
        }
        else if (i == 6) {
          this.responseGames[i].totalGoals = this.games[0].cornerAwayTeam;
        }
        else if (i == 7) {
          this.responseGames[i].totalGoals = this.games[0].totalFaults;
        }
        else if (i == 8) {
          this.responseGames[i].totalGoals = this.games[0].shootsOnTarget;
        }
        else if (i >= 9) {
          this.responseGames[i].two_options = 1;
        }
      }

    }
    else {
      if (typeof (this.games) === 'undefined') {
        return;
      }
      for (let i = 0; i < this.games.length; i++) {
        this.responseGames[i] = new ResponseGame();
        this.responseGames[i].idGame = this.games[i].id;
        this.responseGames[i].teamHome = this.games[i].homeName;
        this.responseGames[i].teamVisit = this.games[i].awayName;
        this.responseGames[i].homeTeamScore = this.games[i].homeTeamScore;
        this.responseGames[i].awayTeamScore = this.games[i].awayTeamScore;
        this.responseGames[i].two_options = this.games[i].two_options;
        this.responseGames[i].dateBegin = this.games[i].dateTime;
        this.responseGames[i].hourBegin = this.games[i].dateTime;
        this.responseGames[i].dateEnd = this.games[i].dateTime;
        this.responseGames[i].hourEnd = this.games[i].dateTime;
        this.responseGames[i].finalResult = this.games[i].homeTeamScore + ',' + this.games[i].awayTeamScore;
        this.responseGames[i].moreBallPossession = this.games[i].moreBallPossession;
        this.responseGames[i].totalCorners = this.games[i].totalCorners;
        this.responseGames[i].totalFaults = this.games[i].totalFaults;
        this.responseGames[i].totalGoals = this.games[i].totalGoals;
        this.responseGames[i].cornerAwayTeam = this.games[i].cornerAwayTeam;
        this.responseGames[i].cornerHomeTeam = this.games[i].cornerHomeTeam;
        this.responseGames[i].yellowCards = this.games[i].yellowCards;
        this.responseGames[i].shootsOnTarget = this.games[i].shootsOnTarget;
      }
    }
  }

  setBet(gameIndex, optionChoosen) {
    if (1 > 2 /*new Date(Date.now()) > this.round.startDateTime*/) {
      this.modalsService.openNotificationModal(this.translate.instant("pg-home.operation-failed"), this.translate.instant("pg-home.operation-failed-info"), true);
    } else {
      // creating new ticket whether is the case
      this.interactionService.ticketPanelState
        .subscribe(data => {
          if (data == null || this.currentTicket.round == null || data.round.id != this.interactionService.homeVisibleRound.value.id) {
            this.currentTicket.round = this.round;
            this.games.forEach(element => {
              element.matchResult = '';
            });

            this.currentTicket.matches = this.games;

            this.currentTicket.quantity = 1;
            this.currentTicket.finalValue = this.round.value;
            this.currentTicket.date = new Date(Date.now());
          }
        });
      //;
      if (this.rankTypeRound && this.currentTicket.matches.find(e => e.id == gameIndex)) {
        if (this.currentTicket.matches.find(e => e.id == gameIndex).matchResult !== '') {
          this.rankArray[parseInt(this.currentTicket.matches.find(e => e.id == gameIndex).matchResult)] = 0;
          this.currentTicket.matches.find(e => e.id == gameIndex).matchResult = '';
        } else {
          this.currentTicket.matches.find(e => e.id == gameIndex).matchResult = optionChoosen;
          this.rankArray[parseInt(optionChoosen)] = 1;
          this.interactionService.setPlayBallAnimation(true);
        }
        this.currentRank = 1;
        for (let index = 1; index < this.rankArray.length + 1; index++) {
          if (!this.rankArray[index]) {
            this.currentRank = index;
            break;
          }
        }
        // Sort by rank
        this.currentTicket.matches.sort((n1, n2) => parseInt(n1.matchResult) - parseInt(n2.matchResult));
      } else {
        // fulfill the array as needed
        if (this.currentTicket.matches[gameIndex].matchResult.includes(optionChoosen)) {
          this.currentTicket.matches[gameIndex].matchResult = this.currentTicket.matches[gameIndex].matchResult.replace(optionChoosen, '');
        } else {

          this.currentTicket.matches[gameIndex].matchResult += ' ' + optionChoosen;
          this.interactionService.setPlayBallAnimation(true);
        }
        // formating and ordering items
        let one, x, two; //MoreBalla, MoreBallb, MoreBallx, totalGoalsa, totalGoalsb, TotalCornersa, TotalCornersb, YellowCardsa, YellowCardsb, CornerHomea, CornerHomeb, CornerAwaya, CornerAwayb, TotalFaultsa, TotalFaultsb, ShootsOnTargeta, ShootsOnTargetb, Penalty90Mina, Penalty90Minb, Goal1stHalfa, Goal1stHalfb, Goal2ndHalfa, Goal2ndHalfb, GGBothScorea, GGBothScoreb, RedCarda, RedCardb: boolean;
        if (this.currentTicket.matches[gameIndex].matchResult.includes('1')) {
          one = true;
        }
        if (this.currentTicket.matches[gameIndex].matchResult.includes('X')) {
          x = true;
        }
        if (this.currentTicket.matches[gameIndex].matchResult.includes('2')) {
          two = true;
        }
        this.currentTicket.matches[gameIndex].matchResult = '';
        if (one) {
          this.currentTicket.matches[gameIndex].matchResult = '1';
        }
        if (x) {
          this.currentTicket.matches[gameIndex].matchResult += 'X';
        }
        if (two) {
          this.currentTicket.matches[gameIndex].matchResult += '2';
        }
      }

      // check final ticket's value
      let doubleBets = 0;
      let tripleBets = 0;
      this.currentTicket.singleBet = true;
      if (!this.rankTypeRound)
        this.currentTicket.matches.forEach(element => {
          if (element.matchResult.length == 2) {
            doubleBets += 1;
            this.currentTicket.singleBet = false;
          } else if (element.matchResult.length == 3) {
            tripleBets += 1;
            this.currentTicket.singleBet = false;
          }
        });
      this.currentTicket.finalValue = (2 ** doubleBets) * (3 ** tripleBets) * (this.currentTicket.round.value * this.currentTicket.quantity);
      this.currentTicket.currencySymbol = this.Currency.currencySymbol;
      this.currentTicket.currencyQuote = this.Currency.quote;

      // update the global ticket's service
      this.interactionService.setTicketPanelState(this.currentTicket);
      this.interactionService.setMatchRank(this.rankArray);
    }
  }


  setXY(event) {
    this.interactionService.setClickX(event.clientX);
    this.interactionService.setClickY(event.clientY);
  }

  formatingCheck(index: number, option: string) {

    if (this.currentTicket == null || this.currentTicket.matches == null || this.currentTicket.round.id != this.interactionService.homeVisibleRound.value.id) {
    } else {
      if (this.rankTypeRound && this.currentTicket.matches.find(e => e.id == index) && this.currentTicket.matches.find(e => e.id == index).matchResult) {
        return true;
      }
      if (this.currentTicket.matches.length >= (index + 1) && this.currentTicket.matches[index].matchResult.includes(option)) {
        return true;
      }
    }
    return false;
  }

  getSelectedRank(index: number) {
    if (this.currentTicket == null ||
      this.currentTicket.matches == null ||
      this.currentTicket.round.id != this.interactionService.homeVisibleRound.value.id ||
      !this.currentTicket.matches.find(e => e.id == index) ||
      this.currentTicket.matches.find(e => e.id == index).matchResult === '') {
      return '-';
    }
    return this.currentTicket.matches.find(e => e.id == index).matchResult;
  }

  openRoundMenu() {
    this.showCloseRoundBtn = true;
    this.roundMenu.open();
  }

  closeRoundMenu() {
    this.showCloseRoundBtn = false;
    this.roundMenu.close();
  }

  getIPAddress() {

    axios.get('http://api.ipify.org/?format=json')
      .then((response) => {

        axios.get('https://api.ipfind.com?ip=' + response.data['ip'] + '&auth=7e2a21d0-0e27-44b6-aa06-8a0d015e266c')
          .then((response) => {

            localStorage.setItem('currentUserCountry', response.data['currencyCode']);
          }, error => {

            localStorage.setItem('currentUserCountry', 'Brazil');
          });

      }, error => {
        localStorage.setItem('currentUserCountry', 'Brazil');
      });
  }

  // --- REGISTER COMPONENTS --//
  get userFormLogin() {
    return this.userValidatingForm.get('userFormLogin');
  }

  get userFormName() {
    return this.userValidatingForm.get('userFormName');
  }

  get userFormLastName() {
    return this.userValidatingForm.get('userFormLastName');
  }

  get userFormEmail() {
    return this.userValidatingForm.get('userFormEmail');
  }

  get userFormConfirmEmail() {
    return this.userValidatingForm.get('userFormConfirmEmail');
  }

  get userJurisdictionCoupon() {
    return this.userValidatingForm.get('userJurisdictionCoupon');
  }

  get userFormCountry() {
    return this.userValidatingForm.get('userFormCountry');
  }

  get userFormCity() {
    return this.userValidatingForm.get('userFormCity');
  }

  get forgotFormEmail() {
    return this.forgotValidatingForm.get('forgotFormEmail');
  }

  generateRandomPassword() {
    let length = 8,
      charset = 'abcde0123456789',
      newPassword = '';

    for (var i = 0, n = charset.length; i < length; ++i) {
      newPassword += charset.charAt(Math.floor(Math.random() * n));
    }

    return newPassword;
  }

  register() {
    let newLogin = (<HTMLInputElement>document.getElementById('orangeForm-login')).value;
    let newName = (<HTMLInputElement>document.getElementById('orangeForm-name')).value;
    let newLastName = (<HTMLInputElement>document.getElementById('orangeForm-lastname')).value;
    let newEmail = (<HTMLInputElement>document.getElementById('orangeForm-email')).value;
    let newConfirmEmail = (<HTMLInputElement>document.getElementById('orangeForm-email2')).value;
    let newJurisdictionCoupon = (<HTMLInputElement>document.getElementById('jurisdiction-coupon')).value;
    let newCountry = (<HTMLInputElement>document.getElementById('orangeForm-country')).value;
    let newCity = (<HTMLInputElement>document.getElementById('orangeForm-city')).value;

    let newPassword = this.generateRandomPassword();

    if (newLogin == '' || /*newName == "" ||*/ newEmail == '' || newConfirmEmail == '') {
      this.modalsService.openNotificationModal(this.translate.instant('pg-app-component.field-required'), this.translate.instant('pg-app-component.field-required-info'), true);
    } else if (newLogin.includes(' ')) {
      this.modalsService.openNotificationModal(this.translate.instant('pg-app-component.field-required'), this.translate.instant('pg-app-component.invalid-character-info'), true);
    } else if (newEmail != newConfirmEmail) {
      this.modalsService.openNotificationModal(this.translate.instant('pg-app-component.email-not-match'), this.translate.instant('pg-app-component.email-not-match-info'), true);
    } else {
      this.registerLoading = true;
      let newUser: User = {
        login: newLogin,
        name: newName,
        lastName: newLastName,
        email: newEmail,
        jurisdictionId: 7,
        parentId: 8,
        password: newPassword,
        country: newCountry,
        city: newCity,
        deleted: false,
        commission: 0,
        jurisdiction: null,
        userPermission: null,
        credit: 0,
        id: 0,
        children: null,
        bank: '',
        agency: '',
        account: '',
        currencyCode: "BRL"
      };

      this.configService.createUser(newUser, newJurisdictionCoupon)
        .subscribe(data => {
          this.configService.sendPasswordToEmail(newUser.name, newUser.login, newUser.email, newUser.password)
            .subscribe(data => {
              if (data) {
                this.modalsService.openNotificationModal(
                  this.translate.instant('pg-app-component.successfull-register'),
                  this.translate.instant('pg-app-component.successfull-register-info') + this.skinConfig.pageTitle,
                  false
                );
                this.modalRegister.hide();
                this.registerLoading = false;
              }
            }, error => {
              this.modalsService.openNotificationModal(this.translate.instant('pg-app-component.erro-on-register'), this.translate.instant('pg-app-component.erro-on-register-info'), true);
              this.modalRegister.hide();
              this.registerLoading = false;
              console.log(error);
            });
        }, error => {
          this.modalsService.openNotificationModal(this.translate.instant('pg-app-component.erro-on-register'), this.translate.instant('pg-app-component.erro-on-register-info'), true);
          this.modalRegister.hide();
          this.registerLoading = false;
        });
    }
  }

  footballGame(i: number, id, name) {
    let stringJsonfootball = JSON.stringify(this.rounds);
    let rounds = JSON.parse(stringJsonfootball);
    var infos = [];
    for (let index = 0; index < rounds.length; index++) {
      var round_infos = this.setRoundPrizes(rounds[index]);

      let dateToDisplay: string = '';
      const roundStartTime = (new Date(rounds[index].startDateTime)).getTime() - (new Date()).getTimezoneOffset() * 60000;
      if (this.datePipe.transform(roundStartTime, 'dd/MM') == this.datePipe.transform(Date.now(), 'dd/MM')) {
        dateToDisplay = this.translate.instant('pg-home.today') + ' ' + this.datePipe.transform(roundStartTime, 'HH:mm');
      } else {
        dateToDisplay = this.datePipe.transform(roundStartTime, 'dd/MM HH:mm');
      }
      switch (this.appComponent.activeLang) {
        case 'pt':
          this.roundName = this.rounds[index].name;
          break;
        case 'en':
          this.roundName = this.rounds[index].nameEng;
          break;
        case 'it':
          this.roundName = this.rounds[index].nameItaly;
          break;
        case 'es':
          this.roundName = this.rounds[index].nameSpan;
          break;
        default:
          break;
      }
      if (id === rounds[index].betradarSportId) {
        infos.push(
          {
            id: rounds[index].uuid4,
            datetime: dateToDisplay,
            sport: this.translate.instant('pg-home.' + name),
            bolao: this.roundName,
            value: this.configService.formatCurrencyValue(rounds[index].value),
            prize: this.configService.formatCurrencyValue(round_infos[1]),
            jackpot: this.configService.formatCurrencyValue(round_infos[0]),
          }
        );
      }
    }

    this.dataSource = infos;
    return;
  }


  showTicketList() {
    this.appComponent.ticketListMobileOpen();
  }
}

