import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfigService } from '../config.service';
import { error } from 'console';
import { AuthService } from '../services/auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  country: string = "";

  constructor(private configService: ConfigService, private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.getCountry();
    return true;
  }

  canActivateChild() {
    return this.canActivate();
 }

  getCountry(): void {
    this.configService.getIPAddress().then((data) => {
      this.configService.getCountry(data.data.ip)
      .then(res => {
        this.checkBlockedCountry(res.data.country, data.data.ip);
      })
    })
  }

  checkBlockedCountry(country: string, ipAdress: string) {
    this.configService.checkBlockedCountry(country, ipAdress)
    .subscribe(res => {
      this.authService.setBlockedCountry(false);
    }, error => {
      this.authService.setBlockedCountry(true);
    });
  }

}
